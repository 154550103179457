import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "../components/header/Menu";
import Personalcare from "../components/header/Personalcare";
import Babymenu from "../components/header/Babymenu";
import ProductDetails from "../pages/productDetails/ProductDetails";
import Register from "../pages/auth/register/Register";
import Protabs from "../pages/productDetails/Protabs";
import DangerousHtml from "../pages/DangerousHtml";
import { Maintenance } from "../pages/Maintenance/Maintenance";
import DynamicFooter from "../components/footer/DynamicFooter";
import FileUploadPage from "../pages/upload/Upload";
import Wishlist from "../pages/wishlist/Wishlist";
import Notification from "../pages/notification/Notification";

import UserDropdown from "../components/header/UserDropdown";
import ChangePassword from "../pages/auth/changePassword/ChangePassword";
import ResetPassword from "../pages/auth/reset_password/ResetPassword";
import EmailVerify from "../pages/auth/reset_password/EmailVerify";
import Login from "../pages/auth/login/Login";
import GoogleMap from "../components/googleMapIntegration/GoogleMap";
import Dashboard from "../pages/afterLogin/Dashboard";
import MoreProducts from "../pages/moreProducts/MoreProducts";

import VerifyEmail from "../pages/auth/VerifyEmail";

import MyOrder2 from "../pages/loginDashboard/myOrders/MyOrder2";
import SubCategory from "../pages/subCategory/SubCategory";
import Slider from "../pages/home/Slider";
// import CartDetails from "../pages/cartDetails/CartDetails";
import OrderStepper from "../pages/orderStepper/OrderStepper";
import HealthRecords from "../pages/loginDashboard/healthrecords/HealthRecords";
import SubCategoryProducts from "../pages/subCategory/SubCategoryProducts";
import SearchProduct from "../pages/searchProduct/SearchProduct";
import NotAvailable from "../pages/notAvailable/NotAvailable";
import ProductRequest from "../pages/loginDashboard/productRequest/ProductRequest";
import DemoTabs from "../pages/loginDashboard/DemoTab";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const LazyHome = lazy(() => import("../pages/home/Home"));
const LazyAbout = lazy(() => import("../pages/aboutus/About"));
const LazyCart = lazy(() => import("../components/cart/Cart"));
const LazyContact = lazy(() => import("../pages/contactus/Contact"));
const LazyLoginDashboard = lazy(() =>
  import("../pages/loginDashboard/LoginDashboard.jsx")
);
const LazyCartDetails = lazy(() => import("../pages/cartDetails/CartDetails"));
// const Lazy  = lazy(() => import());
// const Lazy  = lazy(() => import());
// const Lazy  = lazy(() => import());
// const Lazy  = lazy(() => import());
// const Lazy  = lazy(() => import());
// import About from "../pages/aboutus/About";

const RootRoutes = () => {
  return (
    <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
      <Header />
      <div style={{ flexGrow: 1 }}>
        <Suspense fallback={<div>Loading.. </div>}>
          <Routes>
            <Route exact path="/" element={<LazyHome />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/About" element={<LazyAbout />} />
            <Route exact path="/cart" element={<LazyCart />} />
            <Route
              exact
              path="/productDetails/:id"
              element={<ProductDetails />}
            />
            <Route exact path="/Menu" element={<Menu />} />
            <Route exact path="/Personalcare" element={<Personalcare />} />
            <Route exact path="/Babymenu" element={<Babymenu />} />
            <Route exact path="/pages/contact_us" element={<LazyContact />} />
            <Route
              exact
              path="/LoginDashboard"
              element={<LazyLoginDashboard />}
            />
            <Route exact path="/Protabs" element={<Protabs />} />
            <Route exact path="/dang" element={<DangerousHtml />} />
            <Route exact path="/maintenance" element={<Maintenance />} />
            <Route exact path="/pages/:name" element={<DynamicFooter />} />
            <Route exact path="/cartDetails" element={<LazyCartDetails />} />
            <Route exact path="/everify" element={<EmailVerify />} />
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route exact path="/changepassword" element={<ChangePassword />} />
            <Route exact path="/mapf" element={<GoogleMap />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/products" element={<MoreProducts />} />
            <Route exact path="/product" element={<SearchProduct />} />
            <Route exact path="/moreDetails" element={<NotAvailable />} />

            <Route exact path="/order" element={<MyOrder2 />} />
            <Route exact path="/upload" element={<FileUploadPage />} />
            <Route exact path="/wishlist" element={<Wishlist />} />
            <Route exact path="/notifications" element={<Notification />} />
            <Route exact path="/subCategory/:id" element={<SubCategory />} />
            <Route
              exact
              path="/subCategories/:id/:catId"
              element={<SubCategoryProducts />}
            />
            <Route exact path="/slider" element={<Slider />} />
            <Route exact path="/orderStepper" element={<OrderStepper />} />
            <Route exact path="/healthRecords" element={<HealthRecords />} />
            <Route exact path="/productrequest" element={<ProductRequest />} />

            <Route
              exact
              path="/verifyEmail/:data/:email"
              element={<VerifyEmail />}
            />

            {/* Should be deleted */}
            <Route exact path="/user" element={<UserDropdown />} />
            <Route exact path="/demo" element={<DemoTabs />} />

            {/* <Route exact path="/megamenu" element={<MegaMenu />} /> */}
          </Routes>
        </Suspense>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};
export default RootRoutes;
