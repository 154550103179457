import React from "react";
import { Button } from "@mui/material";
import "./Button.scss";
import LoadingButton from "@mui/lab/LoadingButton";

export const PrimaryButton = ({
  title,
  classNames,
  endIcon,
  startIcon,
  onClick,
}) => {
  return (
    <Button
      type="submit"
      onClick={() =>
        setTimeout(async () => {
          onClick();
        }, 500)
      }
      sx={{
        borderRadius: "5px",

        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          transition: "all 0.5s",
          boxShadow: "0 0 10px 10px var(--primary)",
        },

        "&:active::after": {
          boxShadow: "0 0 0 0 var(--primary)",
          position: "absolute",
          borderRadius: "5px",
          left: 0,
          top: 0,
          opacity: 1,
          transition: "0s",
        },
      }}
      className={`primary_btn ${classNames}`}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {title}
    </Button>
  );
};
export const PrimaryBoldButton = ({
  title,
  classNames,
  endIcon,
  startIcon,
  onClick,
}) => {
  return (
    <Button
      type="submit"
      onClick={() =>
        setTimeout(async () => {
          onClick();
        }, 500)
      }
      sx={{
        borderRadius: "5px",

        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          transition: "all 0.5s",
          boxShadow: "0 0 10px 10px var(--primary)",
        },

        "&:active::after": {
          boxShadow: "0 0 0 0 var(--primary)",
          position: "absolute",
          borderRadius: "5px",
          left: 0,
          top: 0,
          opacity: 1,
          transition: "0s",
        },
      }}
      className={`primary_bold_btn ${classNames}`}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {title}
    </Button>
  );
};
export const GreyButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <Button
      type="submit"
      onClick={() =>
        setTimeout(async () => {
          onClick();
        }, 500)
      }
      sx={{
        borderRadius: "5px",

        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          transition: "all 0.5s",
          boxShadow: "0 0 10px 10px var(--header_bg)",
        },

        "&:active::after": {
          boxShadow: "0 0 0 0 var(--header_bg)",
          position: "absolute",
          borderRadius: "5px",
          left: 0,
          top: 0,
          opacity: 1,
          transition: "0s",
        },
      }}
      className={`greyButton ${classNames}`}
      endIcon={endIcon}
    >
      {title}
    </Button>
  );
};

export const OutlineButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <Button
      onClick={() =>
        setTimeout(async () => {
          onClick();
        }, 500)
      }
      sx={{
        borderRadius: "5px",

        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          transition: "all 0.5s",
          boxShadow: "0 0 10px 10px var(--primary)",
        },

        "&:active::after": {
          boxShadow: "0 0 0 0 var(--primary)",
          position: "absolute",
          borderRadius: "5px",
          left: 0,
          top: 0,
          opacity: 1,
          transition: "0s",
        },
      }}
      className={`outline_btn ${classNames}`}
      endIcon={endIcon}
    >
      {title}
    </Button>
  );
};

export const SecondaryButton = ({
  title,
  classNames,
  endIcon,
  size,
  onClick,
  disabled,
}) => {
  return (
    <Button
      type="submit"
      sx={{
        borderRadius: "5px",

        "&::after": {
          content: '""',
          display: "block",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          transition: "all 0.5s",
          boxShadow: "0 0 10px 10px var(--secondary)",
        },

        "&:active::after": {
          boxShadow: "0 0 0 0 var(--secondary)",
          position: "absolute",
          borderRadius: "5px",
          left: 0,
          top: 0,
          opacity: 1,
          transition: "0s",
        },
      }}
      className={`secondary_btn ${classNames}`}
      endIcon={endIcon}
      size={size}
      onClick={() =>
        setTimeout(async () => {
          onClick();
        }, 500)
      }
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export const LoaderButton = ({ title, classNames }) => {
  return (
    <LoadingButton className={`loader_btn ${classNames}`} loading>
      {title}
    </LoadingButton>
  );
};
