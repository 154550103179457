import photogra from "../assets/images/photogra.jpg";
import vedioghr from "../assets/images/vedioghr.jpg";
import hall from "../assets/images/hall.jpg";
import catering from "../assets/images/catering.jpg";
import payment from "../assets/images/payment.png";
import houston from "../assets/images/houston.jpg";
import newyork from "../assets/images/newyork.jpg";
import washington from "../assets/images/washington.jpg";
import chikago from "../assets/images/chikago.jpg";
import califonia from "../assets/images/califonia.jpg";
import angl from "../assets/images/angl.jpg";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GoogleIcon from "@mui/icons-material/Google";
// import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import SpaIcon from "@mui/icons-material/Spa";
import BlenderIcon from "@mui/icons-material/Blender";
import AddModeratorIcon from "@mui/icons-material/AddModerator";

export const socialIcons = [
  // {
  //   item: FacebookIcon,
  // },
  // {
  //   item: TwitterIcon,
  // },
  // {
  //   item: GoogleIcon,
  // },
  // {
  //   item: InstagramIcon,
  // },
  {
    item: WhatsAppIcon,
  },
];

export const carouseldata = [
  { img: photogra, para: "photogra" },
  { img: vedioghr, para: " Videographer" },
  { img: hall, para: "hall" },
  { img: catering, para: "catering" },
  { img: photogra, para: "photogra" },
  { img: vedioghr, para: " Videographer" },
  { img: hall, para: "hall" },
  { img: catering, para: "catering" },
];

export const categoryitem = [
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40g7rfLd61tu-ORHh11UKe5kabZ3cx3FwcQ&usqp=CAU",
    title: "Deep sea phantasy",
  },
];
export const backslider = [
  {
    img: "https://t4.ftcdn.net/jpg/03/93/90/49/400_F_393904908_nKvNg7bwQi3028HXoikTLVTKeVcE5SKi.jpg",
  },
  {
    img: "https://t3.ftcdn.net/jpg/04/70/72/94/360_F_470729465_u0jtHMVqnTe0dsEd54MkRSnzh3Ehgi8U.jpg",
  },
  {
    img: "https://t4.ftcdn.net/jpg/03/93/90/49/400_F_393904908_nKvNg7bwQi3028HXoikTLVTKeVcE5SKi.jpg",
  },
  {
    img: "https://t3.ftcdn.net/jpg/04/70/72/94/360_F_470729465_u0jtHMVqnTe0dsEd54MkRSnzh3Ehgi8U.jpg",
  },
  {
    img: "https://t4.ftcdn.net/jpg/03/93/90/49/400_F_393904908_nKvNg7bwQi3028HXoikTLVTKeVcE5SKi.jpg",
  },
  {
    img: "https://t3.ftcdn.net/jpg/04/70/72/94/360_F_470729465_u0jtHMVqnTe0dsEd54MkRSnzh3Ehgi8U.jpg",
  },
  {
    img: "https://t4.ftcdn.net/jpg/03/93/90/49/400_F_393904908_nKvNg7bwQi3028HXoikTLVTKeVcE5SKi.jpg",
  },
];
// export const review = [
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
//   {
//     img: gilss_img,
//     parac:
//       " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto nam quidem, quasi aperiam eaque, adipisci vitae ipsam rerum, ducimus alias delectus. Quo sequi porro, sint excepturi laborum fuga similique fugiat. */}",
//     titel: "Alexa figma",
//     btn: "Plumbing",
//     icons: <AiFillStar />,
//   },
// ];
export const servicdata = [
  { id: 1, img: houston, para: "houston" },
  { id: 2, img: newyork, para: " newyork" },
  { id: 3, img: washington, para: "washington" },
  { id: 4, img: chikago, para: "chikago" },
  { id: 5, img: califonia, para: "califonia" },
  { id: 6, img: angl, para: " angl" },
  { id: 7, img: newyork, para: "newyork" },
  { id: 8, img: chikago, para: "chikago" },
];
export const serviceplanneritem = [
  {
    img: "	https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQjetMCux_YxkNj8XblxAJTHd4NR0GzlleSA&usqp=CAU",
    para: "Event manager",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIULZF3zWPND8hmJgO1BZu9ziZHCd_CgQMvQ&usqp=CAU",
    para: "Event manager",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv3b9fwMgjdnLQiJkHxcODXniBCAGF6yPoFw&usqp=CAU",
    para: "Event manager",
  },
];

export const imgData = [
  {
    img: "https://images.pexels.com/photos/208518/pexels-photo-208518.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    img: "https://images.pexels.com/photos/163944/pexels-photo-163944.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    img: "https://images.pexels.com/photos/3683047/pexels-photo-3683047.jpeg?auto=compress&cs=tinysrgb&w=1600",
  },
  {
    img: "  https://images.pexels.com/photos/3683041/pexels-photo-3683041.jpeg?cs=srgb&dl=pexels-anna-shvets-3683041.jpg&fm=jpg",
  },
];

export const footer = {
  about: [
    {
      title: "Privacy Policy",
      path: "privacy_policy",
    },
    {
      title: "Terms And Conditions",
      path: "terms_conditions",
    },
    {
      title: "FAQ's",
      path: "faqs",
    },
    {
      title: "Support",
      path: "contact_us",
    },
  ],
  help: [
    {
      title: "About Us",
      path: "about_us",
    },
    {
      title: " Return & Refund Poilcy",
      path: "return_refund",
    },

    // {
    //   title: "Return Policy",
    //   path: "/",
    // },
    {
      title: "Anti Scam",
      path: "anti_scam",
    },
    // {
    //   title: "Shipping Methods",
    //   path: "/",
    // },
  ],
  payment: {
    desc: "  Quisque id diam vel quam elementum pulvinar etiam non quamgese",
    img: payment,
  },
};

export const dynamicImg = [
  {
    img: "https://offercdn.paytm.com/blog/2019/09/UPI-sweepstakes-banner_50-cashback.jpg",
  },
  {
    img: "https://www.alahli.com/en-us/personal-banking/ncb_offers/PublishingImages/54688--CC-Digital-Campaign-2022-LP-e-980x400-2.jpg",
  },
  {
    img: "https://i.ytimg.com/vi/nbLx96fcwqk/maxresdefault.jpg",
  },
  {
    img: "https://images.ctfassets.net/fzn2n1nzq965/K3ReOAHQ6AnjETRznub9X/661ff26eb5e9568354c8ac2e9bf4f9fd/Generic_Social_Card.png?q=80",
  },
  {
    img: "https://www.icicibank.com/content/dam/icicibank/india/managed-assets/images/offer-zone/credit-debit-card/visa-contactless-offer-d.jpg",
  },
  {
    img: "https://images.livemint.com/img/2022/07/08/600x338/01_(13)_1657274604055_1657274613077.jpg",
  },
];

export const categoryList = [
  {
    icon: MedicationLiquidIcon,
    title: "Pharmacy",
  },
  {
    icon: AddBoxIcon,
    title: "Doctor",
  },
  {
    icon: BabyChangingStationIcon,
    title: "Baby Needs",
  },
  {
    icon: SettingsAccessibilityIcon,
    title: "Personal Care",
  },
  {
    icon: MedicalInformationIcon,
    title: "Health and Nutrition",
  },
  {
    icon: SpaIcon,
    title: "Health Needs",
  },
  {
    icon: BlenderIcon,
    title: "Vitamins",
  },
  {
    icon: AddModeratorIcon,
    title: "Ayurveda",
  },
];

export const userList = [
  {
    user: "test1",
    password: "test1",
  },
];

export const productData = [
  {
    img: "https://static2.medplusmart.com/products/_d00a4db_/BONN0030_L.jpg",
    name: "BONNYBOO",
    para: "Bonny Boo Baby Diaper Pants",
    delivered: true,
    cancelled: false,
    Refund: false,
  },
  {
    img: "https://static2.medplusmart.com/products/_903ac55_/EATR0011_L.jpg",
    name: "EATRITE",
    para: "EATRITE CORN FLAKES PLAIN",
    delivered: false,
    cancelled: true,
    Refund: false,
  },
  {
    img: "https://static2.medplusmart.com/products/_d80cee2_/PATA0195_L.jpg",
    name: "PATANJALI NUTRELA",
    para: "PATANJALI NUTRELA DIABETIC",
    delivered: false,
    cancelled: false,
    Refund: true,
  },
  {
    img: "https://static2.medplusmart.com/products/_3762e37_/EATR0074_L.jpg",
    name: "EATRITE",
    para: "EATRITE DIGESTIVE BISCUITS ",
    delivered: true,
    cancelled: false,
    Refund: false,
  },
  {
    img: "https://static2.medplusmart.com/products/FABK0004_L.jpg",
    name: " FABKARE",
    para: " FABKARE DETERGENT BAR 250GM",
    delivered: false,
    cancelled: true,
    Refund: false,
  },
  {
    img: "https://static2.medplusmart.com/products/_dd504e_/VICK0006_L.jpg",
    name: "VICKS",
    para: "VICKS VAPORUB CREAM 50ML ",
    delivered: false,
    cancelled: false,
    Refund: true,
  },
];
