// Base for production
// export const Base = `http://medical.mobitplus.com/`;

//Base
export const Base = `https://apis.healthpackpharmacy.com/`;

// Base For development
// export const Base = `http://localhost/medical-backend/`;

const CURLPath = {
  json: {
    "Content-Type": "application/json",
  },

  login: "login",
  "login/mobile": "login/mobile",
  "login/mobile/otp": "login/mobile/otp",
  registers: "registers",
  gender: "gender",
  sliders: "sliders",
  banners: "banners",
  logout: "logout",
  category: "category",
  maintanance: "maintanance",
  pages: "pages",
  resetpassword: "resetpassword",
  forgotpassword: "forgotpassword",
  changepassword: "changepassword",
  "pages/contact_us": "pages/contact_us",
  subject: "subject",
  contactUs: "contactUs",
  profile: "profile",
  change_picture: "change_picture",
  profile_update: "profile_update",
  email_varify: "email_varify",
  home: "home",
  cart: "cart",
  "orders/methods": "orders/methods",
  address: "address",
  "product/faviorate": "product/faviorate",
  "orders/add": "orders/add",
  "orders/userwise": "orders/userwise",
  "prescription/upload": "prescription/upload",
  "prescription/get": "prescription/get",
  "prescription/cancel": "prescription/cancel",
  images: "images",
  "orders/status": "orders/status",
  subcategory: "subcategory",
  "product/category": "product/category",
  products: "products",
  "product/subcategory": "product/subcategory",
  "pages/features_heading": "pages/features_heading",
  fetured: "fetured",
  "pages/announcement": "pages/announcement",
  "request/add": "request/add",
  "request/get": "request/get",
  "notify/count": "notify/count",
  "notify/get": "notify/get",
  "notify/add": "notify/add",
  "notify/check": "notify/check",
  "notify/read": "notify/read",
  "orders/cancel": "orders/cancel",
  categories: "categories",
  related: "related",
  ads: "ads",
};

export default CURLPath;
