import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, { GetSession } from "../../../constant/Session";
import { Stringify } from "../../../constant/Util";

export const UpdateCartApi = async (cartItemSend) => {
  const isUserLogin = GetSession(StoredVariables.username);

  const sendData = {
    items: Stringify(cartItemSend.items),
    total_cart: cartItemSend.items.length,
    total_price: cartItemSend.items
      .reduce((total, item) => total + item.Mrp * item.finalQuantity, 0)
      .toFixed(2),
    total_bill_price: cartItemSend.items
      .reduce((total, item) => total + item.BillPrice * item.finalQuantity, 0)
      .toFixed(2),
  };
  // console.log(sendData);

  isUserLogin && (await POST("cart", sendData));

  return true;
};
