import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HealthRecords.scss";
import { Heading1, Heading2 } from "../../../components/heading/Heading";
import { GET, PUT } from "../../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import { Img_url } from "../../../constant";
import Zoom from "react-medium-image-zoom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  OutlineButton,
  SecondaryButton,
} from "../../../components/buttons/Button";
import health_cr from "../../../assets/images/health_cr.png";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import HealthRecordModal from "./HealthRecordModal";
const HealthRecords = () => {
  const [savedList, setSavedList] = useState([]);
  const [comment, setComment] = useState("");
  const [fetch, setFetch] = useState("");
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [healthrecord, setHealthRecord] = useState("");
  const [dataImage, setDataImage] = useState("");
  const myRecords = useSelector((state) => state.userReducer.records);

  useEffect(() => {
    myRecords &&
      setSavedList(myRecords.map((data) => ({ ...data, isShow: false })));
  }, [myRecords]);
  // console.log(savedList);

  const dispatch = useDispatch();

  const handleRecords = async () => {
    const response1 = await GET("prescription/get");
    if (response1.status === 200) {
      dispatch({ type: "SavedRecords", payload: response1.data });
    }
  };
  useEffect(() => {
    handleRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const removeItem = async (data) => {
    const requestData = {
      comment: comment,
      id: data.id,
    };

    const checkdata = {
      comment: "required|min:20",
      id: "required",
    };

    const validation = new Validator(requestData, checkdata);
    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
    } else {
      const response = await PUT("prescription/cancel", requestData);

      if (response.status === 200) {
        toast.error(response.message);
        dispatch({ type: "PresCancel", payload: data });
        setFetch("fetch");
        setComment("");
        setTimeout(() => {
          setFetch("");
        }, 300);

        // setFetch("fetch")
      }
    }

    // const response = await PUT("prescription/cancel", { id: data.id });
    // console.log(response.status);
  };

  useEffect(() => {
    fetch === "fetch" && handleRecords();
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (data) => {
    const element = savedList.findIndex((elem) => elem.id === data.id);

    const changeSavedList = [...savedList];

    if (changeSavedList[element].isShow === true) {
      changeSavedList[element] = {
        ...changeSavedList[element],
        isShow: false,
      };
    } else {
      changeSavedList[element] = {
        ...changeSavedList[element],
        isShow: true,
      };
    }

    setSavedList(changeSavedList);
  };
  const handleImage = (value) => {
    setDataImage(value);
    setModal(true);
  };
  // console.log(savedList);
  return (
    <>
      <div className="container">
        <div className="row">
          {savedList && typeof savedList === "object" ? (
            <>
              {savedList.length === 0 ? (
                <>
                  <div className="d-flex align-items-center justify-content-between flex-wrap  mb-3">
                    <Heading2
                      title="Don't have Records yet"
                      classNames={"my-2"}
                    />
                    <OutlineButton
                      classNames={"my-2"}
                      endIcon={
                        <img
                          src={health_cr}
                          alt="health_cr"
                          width="20px"
                          style={{ marginTop: "-3px" }}
                        />
                      }
                      title="Create new Health Records"
                      onClick={() => navigate("/upload")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 border_bottom_with_padding_in_product pb-4">
                    <Heading1 title="My Health Records" classNames={"my-2"} />
                    <OutlineButton
                      classNames={"my-2"}
                      endIcon={
                        <img
                          src={health_cr}
                          alt="health_cr"
                          width="20px"
                          style={{ marginTop: "-2px" }}
                        />
                      }
                      title="Create new Health Records"
                      onClick={() => navigate("/upload")}
                    />
                  </div>
                  {savedList.map((data, i) => (
                    <div key={i} className="col-sm-6 col-lg-4 col-xl-3">
                      <article className="card mb-3">
                        <div
                          className="text-center"
                          onClick={() => handleImage(`${Img_url}${data.image}`)}
                        >
                          {/* <Zoom> */}
                          <img
                            className="card__img"
                            src={`${Img_url}${data.image}`}
                            alt="frontend-mentor-website-qrcode"
                            style={{
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                          {/* </Zoom> */}
                        </div>
                        {data.isShow !== true ? (
                          <div>
                            <div className="card__content">
                              {/* <Heading2
                                title="Records"
                                classNames="mt-3 mb-2"
                              /> */}
                              <p className="fs_15 fw_500 mt-2">
                                Name :{" "}
                                <span
                                  className="fs_12"
                                  style={{ color: `var(--dim_txt)` }}
                                >
                                  {data.name}
                                </span>
                              </p>
                              <p className="fs_15 fw_500 ">
                                Doctor's name :{" "}
                                <span
                                  className="fs_12"
                                  style={{ color: `var(--dim_txt)` }}
                                >
                                  {data.doctor_name}
                                </span>
                              </p>
                              <p className="fs_15 fw_500 ">
                                Status :{" "}
                                <span
                                  className="fs_12"
                                  style={{ color: `var(--dim_txt)` }}
                                >
                                  {/* {data.status_id === 5 && "Cancelled"}
                                  {data.status_id === 3 && "Ordered"}
                                  {data.status_id === 1 && "Pending"} */}
                                  {data.status.status}
                                </span>
                              </p>
                            </div>
                            {data.status === null || data.status_id === 5 ? (
                              <Button
                                fullWidth
                                className="mt-2 "
                                variant="outlined"
                                disabled
                              >
                                Cancelled
                              </Button>
                            ) : data.status_id === 4 ? (
                              <Button
                                fullWidth
                                className="mt-2 "
                                variant="outlined"
                                disabled
                              >
                                Rejected
                              </Button>
                            ) : (
                              <SecondaryButton
                                classNames="mt-2 w-100"
                                title="Remove"
                                onClick={() => handleChange(data)}
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            <Heading2
                              title="Please give comment *"
                              classNames="mt-3"
                            />
                            <textarea
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              name="message"
                              id=""
                              rows="5"
                              placeholder="Comment..."
                              className="tb mt-2 login_field mb-3"
                            ></textarea>
                            <div className="d-flex align-items-center">
                              {" "}
                              <OutlineButton
                                onClick={() => removeItem(data)}
                                title="Submit"
                                classNames="me-3"
                              />
                              <SecondaryButton
                                title="Cancel"
                                onClick={() => handleChange(data)}
                              />
                            </div>
                          </>
                        )}
                      </article>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          )}
        </div>
        <HealthRecordModal
          open={modal}
          setOpen={setModal}
          data={dataImage}
          setDataImage={setDataImage}
        />
      </div>
    </>
  );
};

export default HealthRecords;
