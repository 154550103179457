import { Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { OutlineButton, PrimaryButton } from "../../components/buttons/Button";
import { PrimaryButton } from "../../components/buttons/Button";

import { Heading1, Heading2 } from "../../components/heading/Heading";
import {
  IsProductAdded,
  useCartFunctanilty,
} from "../../components/useCustomHooks/useFunctions";
import { GET } from "../../constant/RequestAuthService";
import { Json, SubCategoryProduct } from "../../constant/Util";
import { GetSession } from "../../constant/Session";
import { CategoryCardLoader } from "../../components/skeletons/Loader";
import ProductCard from "../../components/productCard/ProductCard";

const SubCategoryProducts = () => {
  const { id } = useParams();
  const { catId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subCategoryProduct = SubCategoryProduct();
  // const { pathname } = useLocation();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [subCat, setSubCat] = useState("");
  const categoryList = useSelector((state) => state.apiReducer);
  const { HandlewishtList, AddToCart, Decrement, Increment, DeleteCart } =
    useCartFunctanilty();
  IsProductAdded();
  const CatMenu = useSelector((state) => state.apiReducer.HeaderMenus);
  // console.log(CatMenu);

  useEffect(() => {
    const findCat = CatMenu?.find(
      (item) => parseInt(item.id) === parseInt(catId)
    );
    setName(findCat?.name);
    // console.log(findCat.name);
  }, [id, catId, CatMenu]);

  // console.log(name);

  useEffect(() => {
    handleCategory();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategory = async () => {
    setLoading(true);
    const response1 = await GET("product/subcategory", { id: id });
    if (response1.status === 200) {
      setLoading(false);
      dispatch({
        type: "subCategoryProducts",
        payload: response1.data === null ? [] : response1.data,
      });
      setLoading(false);
    }
  };

  const category_id = Json(GetSession("C_ID"));
  // const sub_category_id = pathname.slice(
  //   pathname.lastIndexOf("/") + 1,
  //   pathname.length
  // );

  // useEffect(() => {
  //   if (categoryList.categoryList && categoryList.categoryList.length !== 0) {
  //     const categoryName = categoryList.categoryList.find(
  //       (item) => item.id === parseInt(category_id)
  //     );
  //     // console.log(category_id);
  //     setName(categoryName?.name);
  //   }
  // }, [category_id, id, categoryList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (categoryList.HeaderMenus && categoryList.HeaderMenus.length !== 0) {
      const findCatList = categoryList.HeaderMenus.find(
        (item) => item.name === name
      );
      // console.log(findCatList);
      if (findCatList) {
        const findSubCat = findCatList.sub_category.find(
          (item) => item.id === parseInt(id)
        );
        // console.log(findSubCat);
        setSubCat(findSubCat?.name);
      }
    }
  }, [categoryList, name, id]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(subCat);

  // const ShowMoreProduct = async () => {
  //   const sendData = {
  //     category_id,
  //     input: null,
  //     sub_category_id,
  //     name,
  //   };

  //   const response = await POST("products", sendData);
  //   if (response.status === 200) {
  //     dispatch({
  //       type: "getSearchProduct",
  //       payload: response.data,
  //     });
  //     SetSession("sendData", Stringify(sendData));
  //     navigate("/product");
  //   }
  // };

  const Bread = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Heading2
          title={name}
          classNames="primary pointer"
          onClick={() => navigate(`/subCategory/${category_id}`)}
        />
        <p>{subCat}</p>
      </Breadcrumbs>
    );
  };

  return (
    <>
      <div className="d-flex flex-column">
        {loading ? (
          <>
            <div className="container ">
              <div className="mt-3 bread_cum">
                <Bread />
              </div>
              <div className="row mt-2">
                {[...Array(8).keys()].map((data, i) => (
                  <div key={i} className=" col-md-4 col-xl-3 col-xxl-2  mb-4">
                    <CategoryCardLoader />
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className=" pt-md-0 d-flex flex-column">
              <div className="container gx-3 ">
                <div className="mt-3 bread_cum">
                  <Bread />
                </div>{" "}
                {/* {subCategoryProduct.length !== 0 && (
                  <div className="d-flex align-items-center justify-content-end  mt-3 mt-sm-0 ">
                    <OutlineButton
                      title="View more"
                      onClick={ShowMoreProduct}
                    />
                  </div>
                )} */}
                <div className="row my-3">
                  {subCategoryProduct.length ? (
                    <>
                      {subCategoryProduct.map((data, id) => (
                        <div
                          key={id}
                          className="col-md-4 col-xl-3 col-xxl-2 py-2 px-0 p-md-3"
                        >
                          {/* <Grid className="top_offer_card br_8 ">
                            <Grid
                              onClick={() =>
                                navigate(`/productDetails/${data.id}`)
                              }
                            >
                              <Grid className="mx-2">
                                {data?.picture ? (
                                  <img
                                    src={`${Img_url}${data.picture}`}
                                    // src={data.product.picture[0].name}
                                    className="img-fluid top_offer_card_inner_div br_8"
                                    alt="top_offer"
                                    style={{
                                      height: "150px",
                                      display: "block",
                                      margin: "10px auto",
                                    }}
                                  />
                                ) : (
                                  <>
                                    {isFrontImages &&
                                    isFrontImages.length !== 0 ? (
                                      <img
                                        src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                        className="img-fluid top_offer_card_inner_div br_8"
                                        alt="top_offer"
                                        style={{
                                          height: "150px",
                                          display: "block",
                                          margin: "10px auto",
                                        }}
                                      />
                                    ) : (
                                      <CircularProgress color="secondary" />
                                    )}
                                  </>
                                )}
                              </Grid>
                              <p className="mt-2  mx-3 fs_14 fw_500">
                                {data.name}
                              </p>
                              <p className="mt-2  fs_15 fw_700 ">{data.para}</p>
                              <div className="d-flex justify-content-between align-items-center mt-2 pb-3">
                                {data?.Mrp && (
                                  <p className="  primary_col fs_14 fw_600 mt-1 mx-3">
                                    Rs {data?.Mrp}
                                  </p>
                                )}
                              </div>
                            </Grid>
                            <Grid className="left_div_top_offer">
                              <br />

                              <IconButton
                                onClick={() => HandlewishtList(data.id)}
                                className="top_oofer_icon"
                              >
                                {getSavedWishList &&
                                getSavedWishList.find(
                                  (item) => item.product_id === data.id
                                ) ? (
                                  <FavoriteIcon
                                    sx={{ color: `var(--secondary)` }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </IconButton>
                              <br />
                              <IconButton className="top_oofer_icon">
                                <ShareIcon />
                              </IconButton>
                            </Grid>{" "}
                            <Grid className="right_div_top_offer">
                              {data.is_fetured === 1 && (
                                <StarRateIcon sx={{ color: "#E6A300" }} />
                              )}
                              {cartItemSend.items.find(
                                (product) => product.id === data.id
                              ) && (
                                <Grid className="mt-2">
                                  <TaskAltIcon
                                    sx={{ color: "var(--primary)" }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            <Grid className="w-100 mb-4 d-flex align-items-center justify-content-start">
                              {!data?.Mrp ||
                          !data?.total_qty ||
                          data.total_qty === "0" ||
                          data.total_qty === 0 ||
                          data.total_qty === "0.00" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    disabled
                                    className=" mx-3 w-100"
                                  >
                                    Not Available
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {!cartItemSend.items.find(
                                    (product) => product.id === data.id
                                  ) ? (
                                    <PrimaryButton
                                      onClick={() => AddToCart(data)}
                                      title="Add To Cart"
                                      classNames=" mx-3 w-100"
                                    />
                                  ) : (
                                    <div className="d-flex">
                                      <Grid className="d-flex align-items-center">
                                        <IconButton
                                          onClick={() => Decrement(data.id)}
                                          className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                        >
                                          -
                                        </IconButton>
                                        <Typography className="px-2 fs-7 fw-bold">
                                          {data.finalQuantity}
                                        </Typography>
                                        <IconButton
                                          onClick={() => Increment(data)}
                                          className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                        >
                                          +
                                        </IconButton>
                                      </Grid>
                                      <SecondaryButton
                                        onClick={() => DeleteCart(data.id)}
                                        title="Remove"
                                        classNames=" w-100"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid> */}{" "}
                          <ProductCard
                            data={data}
                            HandlewishtList={HandlewishtList}
                            AddToCart={AddToCart}
                            Increment={Increment}
                            Decrement={Decrement}
                            DeleteCart={DeleteCart}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <Heading2
                        title="No item related to this Sub Category"
                        classNames="mb-3"
                      />
                      <div>
                        <Heading1
                          title="Browse all products"
                          classNames="mb-3"
                        />
                        <PrimaryButton
                          title="View All Products"
                          onClick={() => navigate("/products")}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}{" "}
      </div>
    </>
  );
};

export default SubCategoryProducts;
