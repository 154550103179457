import React, { useState } from "react";
import login_vector from "../../../assets/images/login_vector.jpg";
import "./Register.scss";
import { Link } from "react-router-dom";
import {
  LoaderButton,
  SecondaryButton,
} from "../../../components/buttons/Button";
// import axios from "axios";
// import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import StoredVariables, { GetSession } from "../../../constant/Session";

import { useNavigate } from "react-router-dom";
import * as Validator from "validatorjs";
import { GET, POST } from "../../../constant/RequestAuthService";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

const Register = () => {
  const [genderdata, setGenderData] = useState("");
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    c_password: "",
    gender_id: 1,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const {
    phone,
    email,
    password,
    c_password,
    gender_id,
    last_name,
    first_name,
  } = registerData;

  useEffect(() => {
    handleGender();
  }, []);

  const handleGender = async () => {
    const response1 = await GET("gender");
    if (response1.status === 200) {
      setGenderData(response1.data);
    }
  };

  // console.log(registerData.gender_id);
  const navigate = useNavigate();

  const isLogin = GetSession(StoredVariables.username);

  useEffect(() => {
    isLogin && navigate("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    e.preventDefault();

    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requestData = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      email: email,
      password: password,
      c_password: c_password,
      gender_id: gender_id,
    };

    const checkdata = {
      first_name: "required",
      last_name: "required",
      phone: "required|digits_between:10,13",
      gender_id: "required",
      email: "required|email",
      password: "required|min:8",
      c_password: "required|same:password",
    };
    const validation = new Validator(requestData, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);
    } else {
      const response = await POST("registers", requestData);
      console.log(response.status);
      if (response.status === 200) {
        setLoading(true);
        toast.success(response.message);
        toast.success(
          "Verification link has been send to your registered mail id"
        );
        navigate("/login");
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(true);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="container p-4 mt-5">
        <div className="row">
          <div className="col-sm-6">
            {" "}
            <img src={login_vector} alt="log" className="img-fluid" />
          </div>
          <div className="col-sm-6">
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <div className="login_btns">
                <div
                  className="d-flex align-items-center flex-wrap "
                  style={{ gap: "23px" }}
                >
                  <input
                    type="text"
                    placeholder="First Name *"
                    className="reg_sm_field"
                    name="first_name"
                    value={registerData.first_name}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Last Name *"
                    className="reg_sm_field"
                    name="last_name"
                    value={registerData.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="d-flex align-items-center flex-wrap mt-4 "
                  style={{ gap: "23px" }}
                >
                  <input
                    type="email"
                    placeholder="Email Address *"
                    className="reg_sm_field "
                    name="email"
                    value={registerData.email}
                    onChange={handleChange}
                  />{" "}
                  <input
                    type="text"
                    placeholder="Mobile No. *"
                    className="reg_sm_field"
                    name="phone"
                    value={registerData.phone}
                    onChange={handleChange}
                  />
                  <select
                    name="gender_id"
                    value={registerData.gender_id}
                    onChange={handleChange}
                    className="reg_sm_field "
                  >
                    <option>Select Gender</option>
                    {genderdata && genderdata.length > 0
                      ? genderdata.map((data, i) => (
                          <option key={data.id} value={data.id}>
                            {data.gender}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>

                <div
                  className="d-flex align-items-center flex-wrap mt-4 "
                  style={{ gap: "23px" }}
                >
                  <div className="flex-grow-1 relative">
                    <input
                      type={!showPassword1 ? "text" : "password"}
                      placeholder="Password *"
                      className="reg_sm_field w-100"
                      name="password"
                      value={registerData.password}
                      onChange={handleChange}
                    />
                    <IconButton
                      className="paswword_icon pointer"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </div>
                  <div className="flex-grow-1 relative">
                    <input
                      type={!showPassword2 ? "text" : "password"}
                      placeholder="Confirm password *"
                      className="reg_sm_field w-100"
                      name="c_password"
                      value={registerData.c_password}
                      onChange={handleChange}
                    />
                    <IconButton
                      className="paswword_icon pointer"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div>
                  <p className="fs_14 mt-3 ms-2 mb-3">
                    Already a member?
                    <Link
                      to="/login"
                      style={{ textDecoration: "none" }}
                      className="fw_600 primary hover_a"
                    >
                      &nbsp;Sign in
                    </Link>
                  </p>
                </div>
                {loading ? (
                  <LoaderButton classNames="login_btns" title="Register" />
                ) : (
                  <SecondaryButton classNames="login_btns" title="Register" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
