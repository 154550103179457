import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  count: null,
  msg: null,
};

const notificationReducer = createReducer(initialState, {
  notifCount: (state, action) => {
    state.count = action.payload;
  },
  notifmssg: (state, action) => {
    state.msg = action.payload;
  },
});

export default notificationReducer;
