import React, { memo } from "react";
import { useSelector } from "react-redux";
import { BannerLoader } from "../../components/skeletons/Loader";

const DynamicAdd = ({ src, classNames }) => {
  const getBanner = useSelector((state) => state.apiReducer);

  return (
    <>
      <div className="container p-0">
        {getBanner.banner && getBanner.banner.length > 0 ? (
          <img
            src={src}
            alt="src"
            className={`img-fluid ${classNames}`}
            style={{
              borderRadius: "8px",
              display: "block",
              height: "200px",
              width: "1600px",
            }}
          />
        ) : (
          <BannerLoader />
        )}
      </div>
    </>
  );
};

export default memo(DynamicAdd);
