import { useEffect, useLayoutEffect, useState } from "react";
import StoredVariables, { GetSession } from "../../../constant/Session";
import { GET } from "../../../constant/RequestAuthService";
import { Json } from "../../../constant/Util";
import { useDispatch } from "react-redux";

const SavedData = ({ setFetch, fetch }) => {
  const [items, setItems] = useState([]);
  const [totalCart, setTotalCart] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [total_bill_price, setTotal_bill_Price] = useState(0);
  // const allProduct = useSelector((state) => state.cartsReducer.allProduct);

  const dispatch = useDispatch();

  const getCart = async () => {
    if (GetSession(StoredVariables.username)) {
      const response1 = await GET("cart");
      if (response1.status === 200) {
        // console.log(response1?.data.total_bill_price);
        setItems(
          Json(response1.data && response1.data[0] && response1.data[0].items)
        );
        setTotalCart(
          response1.data &&
            response1.data[0] &&
            parseInt(response1.data[0].total_cart)
        );
        setTotalPrice(
          response1.data &&
            response1.data[0] &&
            parseInt(response1.data[0].total_price)
        );
        setTotal_bill_Price(parseInt(response1?.data?.total_bill_price));
        setFetch("savedData");
        setTimeout(() => {
          setFetch("");
        }, 300);
      }
    }
  };

  useLayoutEffect(() => {
    getCart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let savedData = {
      items: items === false || items === null ? [] : items,
      totalCart,
      totalPrice,
      total_bill_price,
    };
    // console.log(savedData);
    fetch === "savedData" &&
      dispatch({ type: "SavedData", payload: savedData });
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  return true;
};

export default SavedData;
