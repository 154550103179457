import React, { memo, useEffect, useState } from "react";
import { GET } from "../../../constant/RequestAuthService";
import { DECODE } from "../../../constant/Util";

const WhyChooseUs = () => {
  const [data, setData] = useState("");

  const getWhyChooseUs = async () => {
    const { status, data } = await GET("pages", { id: "why_choose_us" });
    if (status === 200) {
      setData(DECODE(data));
    }
  };

  useEffect(() => {
    getWhyChooseUs();
  }, []);

  return (
    <>
      <div className="container mt-3">
        <div className="row ">
          <div className="col-sm-12  pb-3">
            <h1 fontWeight={600} className="heading_banner mb-3">
              Why Choose Us
            </h1>
            <div
              className="border_icons_cat shadow-sm position-relative overflow-hidden"
              style={{ paddingBottom: "70px" }}
            >
              <div className="custom-shape"></div>
              <div className="custom-shape2"></div>
              <div className="dynamic_content">
                <div dangerouslySetInnerHTML={{ __html: data }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WhyChooseUs);
