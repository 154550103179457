import { IconButton } from "@mui/material";
import React, { memo, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Cart from "../cart/Cart";

import { useSelector } from "react-redux";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid var(--primary)`,
    padding: "0 4px",
    background: ` var(--secondary)`,
    color: "#fff",
  },
}));
const Carts = () => {
  const [open, setOpen] = useState(false);

  const cartItems = useSelector((state) => state.cartsReducer.items);

  return (
    <>
      <IconButton
        aria-label="cart"
        onClick={() => setOpen(!open)}
        sx={{ paddingRight: "14px" }}
      >
        <StyledBadge
          badgeContent={cartItems && cartItems.length ? cartItems.length : "0"}
          // sx={{ backgroundColor: `var(--secondary)` }}
        >
          <ShoppingCartIcon className="hover_icon" />
        </StyledBadge>
      </IconButton>
      <Cart open={open} setOpen={setOpen} />
    </>
  );
};

export default memo(Carts);
