import { IconButton } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import "./Cart.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch } from "react-redux";
import { UpdateCartApi } from "../../pages/home/topOffers/AddCartFunction";
import { toast } from "react-toastify";
import {
  CartItem,
  CartItemSend,
  IsOnline,
  UserLogin,
} from "../../constant/Util";
import { useNavigate } from "react-router-dom";
import { OutlineButton, PrimaryBoldButton } from "../buttons/Button";
import { Heading2 } from "../heading/Heading";
import { AnimatePresence, motion } from "framer-motion";
import StoredVariables, { SetSession } from "../../constant/Session";
const Cart = ({ open, setOpen }) => {
  const cartItem = CartItem();
  const cartItemSend = CartItemSend();
  const navigate = useNavigate();
  const [fetch, setFetch] = useState("nofetch");
  const isUserLogin = UserLogin();

  // console.log(cartItemSend);

  useEffect(() => {
    fetch === "fetch" && UpdateCartApi(cartItemSend);
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteCart = async (id) => {
    if (isUserLogin && IsOnline) {
      await dispatch({ type: "DeleteItems", payload: id });
      setFetch("fetch");
      setTimeout(() => {
        setFetch("");
      }, 300);
    } else {
      toast.error("Please login first");
      navigate("/login");
    }
  };

  const dispatch = useDispatch();

  const Increment = async (data) => {
    // console.log(data);
    dispatch({ type: "UpdateData", payload: data });
    await dispatch({ type: "IsProductAdded" });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const decrement = async (id) => {
    dispatch({ type: "DegradeData", payload: id });
    await dispatch({ type: "IsProductAdded" });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const navigateCartDetail = () => {
    navigate("/CartDetails");
    setOpen(false);
  };

  const navigateCheckout = () => {
    if (isUserLogin && IsOnline) {
      navigate("/orderStepper");
    } else {
      toast.error("Please login first");
      SetSession(StoredVariables.checkout, "checkout");
      navigate("/login");
    }

    setOpen(false);
  };

  return (
    <>
      <div
        className={
          open
            ? "overlay_bg open_cart  min_h_cart px-0"
            : "overlay_bg close_cart  min_h_cart px-0"
        }
      >
        <div className="d-flex align-items-center justify-content-between br_s_primary pb-2 ">
          {/* <h2 className="fs-4"> Your Cart</h2> */}
          <Heading2 classNames="ms-2" title="Your Cart" />
          <IconButton
            sx={{ color: `var(--primary)` }}
            onClick={() => setOpen(!open)}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
        <div
          style={{ height: "80vh", overflow: "auto" }}
          className="container-fluid p-0"
        >
          <AnimatePresence>
            {cartItem && cartItem.length !== 0 ? (
              cartItem.map((data, i) => (
                <motion.div
                  key={i}
                  layout
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{ duration: 0.5 }}
                  className="d-flex gap-2 borderbottom py-3 "
                >
                  {/* <div className="w-25 ms-2">
                    {/* <div className="d-flex align-items-center justify-content-center">
                      {data.picture && typeof data.picture !== "object" ? (
                        <img
                          src={`${Img_url}${data.picture}`}
                          alt="list-img-cart"
                          className="list-img"
                        />
                      ) : (
                        <>
                          {isFrontImages ? (
                            <img
                              src={`${Img_url}${isFrontImages[3].credencial_content}`}
                              className="list-img-cart"
                              alt="top_offer"
                            />
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </>
                      )}
                    </div> */}
                  {/* </div> */}
                  <div className=" w-100 mx-2">
                    <Grid className="added-items">
                      <Grid className=" ">
                        <Grid className="d-flex justify-content-between align-items-center">
                          <p className=" fs-6 fw_600">{data.name}</p>

                          <p className="fs-6 fw_600">
                            <span
                              className=" ms-2 me-2  fw_700"
                              style={{
                                textDecoration: "line-through",
                                color: `var(--secondary)`,
                                marginTop: "2px",
                              }}
                            >
                              {/* {data?.Mrp} */}
                              {(data.Mrp * data.finalQuantity).toFixed(2)}
                            </span>
                            &#8377;
                            {(data.BillPrice * data.finalQuantity).toFixed(2)}
                          </p>
                        </Grid>
                        <Grid className="d-flex justify-content-between align-items-center"></Grid>
                        <Grid className="d-flex align-items-center justify-content-between">
                          <Grid className="d-flex align-items-center">
                            <IconButton
                              onClick={() => decrement(data.id)}
                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                            >
                              -
                            </IconButton>
                            <Typography className="px-2 fs-7 fw-bold">
                              {data.finalQuantity}
                            </Typography>
                            <IconButton
                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                              onClick={() => Increment(data)}
                            >
                              +
                            </IconButton>
                          </Grid>
                          <Grid className="d-flex align-items-center">
                            <IconButton
                              onClick={() => deleteCart(data.id)}
                              style={{ padding: "5px" }}
                            >
                              <DeleteOutlineIcon className="d-ico" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </motion.div>
              ))
            ) : (
              <h4 className="mt-3  ms-2 lead">No Item Selected</h4>
            )}
          </AnimatePresence>
        </div>
        <div className="total-sec row p-0">
          <h6 className="py-2" style={{ color: "#f9315a" }}>
            Note: Price may vary depending on the product's batch
          </h6>
          <hr />
          <div className="col-sm-12">
            <Grid className="d-flex justify-content-between align-items-center color-primary">
              <Typography className="fw-bold fs-6">Mrp</Typography>
              <Typography className="fw-bold fs-6">
                &#8377; {cartItemSend?.total_price?.toFixed(2)}
              </Typography>
            </Grid>
            <Grid className="d-flex justify-content-between align-items-center color-primary">
              <Typography className="fw-bold fs-6 pb-1">Discount </Typography>
              <Typography className="fw-bold fs-6">
                &#8377;
                {(
                  cartItemSend?.total_price - cartItemSend?.total_bill_price
                ).toFixed(2)}
              </Typography>
            </Grid>
            <hr />
            <Grid className="d-flex justify-content-between align-items-center color-primary">
              <Typography className="fw-bold fs-6 pt-1">
                Total Payable
              </Typography>
              <Typography className="fw-bold fs-6">
                &#8377; {cartItemSend?.total_bill_price?.toFixed(2)}
              </Typography>
            </Grid>

            <Grid className="d-flex align-items-center justify-content-between mt-2">
              <PrimaryBoldButton title="Checkout" onClick={navigateCheckout} />

              <OutlineButton onClick={navigateCartDetail} title="View Cart" />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Cart);
