import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, memo } from "react";
// import BigCategoryDropdown from "./BigCategoryDropdown";
import { Link, useNavigate } from "react-router-dom";
import { SecondaryButton } from "../buttons/Button";
import UserDropdown from "./UserDropdown";
import PersonIcon from "@mui/icons-material/Person";
import AnnouncementBar from "../../components/announcementBar/AnnouncementBar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { GET } from "../../constant/RequestAuthService";
import Carts from "./Carts";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { Img_url } from "../../constant";
// import HomeIcon from "@mui/icons-material/Home";
import CategoriesMenu from "./CategoriesMenu";
import Drop from "../drop/Drop";
import Notification from "./Notification";
import { SetSession } from "../../constant/Session";

const CategoryList = ({ userData, isUser, setIsuser }) => {
  const [headers, setHeaders] = useState(false);
  const [showDrop, setShowDrop] = useState(false);

  const userProfile = useSelector(
    (state) => state.userReducer.userProfile.data
  );

  const dispatch = useDispatch();

  const changeNavbar = () => {
    if (window.scrollY >= 66) {
      setHeaders(true);
    } else {
      setHeaders(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);

  const navigate = useNavigate();

  const categoryList = useSelector((state) => state.apiReducer);
  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);

  useEffect(() => {
    if (categoryList && categoryList.categoryList.length === 0) {
      handleCategory();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategory = async () => {
    // console.log("hy");
    const response1 = await GET("category");
    if (response1.status === 200) {
      dispatch({ type: "categoryList", payload: response1.data });
    }
  };

  const handleWishList = () => {
    SetSession("activetabsMenu", 3);
    navigate("/loginDashboard");
  };

  return (
    <>
      <Grid
        className="show_head_sticky h_on_sm"
        sx={{ background: `var(--primary)` }}
      >
        <Grid
          style={{ width: "98%" }}
          // className={`container px-0 px-lg-3`}
          className={
            headers ? "container px-2 px-lg-3 py-3" : "container px-2 px-lg-3"
          }
        >
          <Grid className="container p-0">
            <Grid className=" row gx-0 d-flex justify-content-center ">
              <Grid
                className=" ps-0 d-flex flex-nowrap justify-content-between align-items-center"
                container
              >
                <div className="d-flex align-items-center">
                  <Link
                    style={{
                      textDecoration: "none",
                      height: headers ? "" : "0px",
                    }}
                    to="/"
                  >
                    {/* <img
                      src={logo}
                      alt="logo"
                      className="img-fluid"
                      width={headers ? "60px" : "0px"}
                    /> */}
                    {isFrontImages && isFrontImages.length !== 0 ? (
                      <img
                        src={
                          isFrontImages &&
                          `${Img_url}${isFrontImages[1].credencial_content}`
                        }
                        alt="logo"
                        className="img-fluid"
                        width={headers ? "125px" : "0px"}
                      />
                    ) : (
                      <CircularProgress color="secondary" />
                    )}
                  </Link>
                  {/* <BigCategoryDropdown headers={headers} /> */}
                </div>

                <div
                  className="flex-grow-1 mx-lg-2 ps-3 "
                  style={{ display: `${headers ? "" : "none"}` }}
                >
                  <SearchBar />
                </div>

                <div
                  className="flex-grow-1"
                  style={{
                    display: `${headers ? "none" : ""}`,
                  }}
                >
                  <div className=" d-none big_screen_menu">
                    <CategoriesMenu />
                  </div>

                  <div className="d-none med_screen_menu">
                    <Drop />
                  </div>
                </div>

                <div
                  className={`d-flex align-items-center flex-wrap flex-nowrap`}
                >
                  <div className="d-none">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      className="px-2 fs_15 fw_500"
                      to="/"
                    >
                      Home
                    </Link>
                    {/* <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      className="px-2 fs_15 fw_500"
                      to="/slider"
                    >
                      Offers
                    </Link> */}
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      className="px-2 fs_15 fw_500"
                      to="/pages/about_us"
                    >
                      About us
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                      className="px-2 fs_15 fw_500"
                      to="/pages/contact_us"
                    >
                      Contact us
                    </Link>
                  </div>

                  {/* {headers && (
                    <GreyButton
                      title="Upload"
                      classNames="me-2"
                      onClick={() => navigate("/upload")}
                    />
                  )} */}

                  <div style={{ display: `${!headers ? "none" : "block"}` }}>
                    <div className=" d-flex flex-wrap justify-content-between align-items-center ">
                      {/* <span style={{ padding: "8px" }} className="css-tooltip">
                        <Link to="/">
                          <HomeIcon sx={{ color: "#fff" }} />
                        </Link>
                        <span className="css-tooltip-title">Home</span>
                      </span> */}
                      <span
                        className="d-none big_screen_menu"
                        style={{ padding: "8px" }}
                        // className="css-tooltip"
                      >
                        {/* <UploadFileRoundedIcon
                            sx={{
                              color: "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => navigate("/upload")}
                          /> */}
                        <SecondaryButton
                          title="Upload Prescription"
                          endIcon={<UploadFileRoundedIcon />}
                          onClick={() => navigate("/upload")}
                        />
                        {/* <span className="css-tooltip-title">
                            Upload Prescription
                          </span> */}
                      </span>
                      {/* {userData !== null && userData !== "" && `${userData}`} */}
                      {(userData !== null && userData !== "") || userProfile ? (
                        <UserDropdown
                          userData={userData}
                          isUser={isUser}
                          setIsuser={setIsuser}
                        />
                      ) : (
                        <ClickAwayListener
                          onClickAway={() => setShowDrop(false)}
                        >
                          <div
                            className="dropdown_menu"
                            onClick={() => setShowDrop(true)}
                          >
                            <IconButton className="hover_it_drop hover_icon">
                              {/* {userData !== null && userData !== "" && `${userData}`} */}
                              <PersonIcon className="hover_icon" />
                            </IconButton>
                            <ul
                              className={` ${
                                showDrop && "show_login_reg_dropdown"
                              } login_reg_dropdown d-flex  align-items-center `}
                            >
                              <div className=" d-flex  align-items-center ">
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to="/login"
                                >
                                  <div>
                                    <SecondaryButton
                                      title="Login"
                                      classNames="me-2 drop_btn"
                                    />
                                  </div>
                                </Link>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to="/register"
                                >
                                  <SecondaryButton title="Register" />
                                </Link>
                              </div>
                            </ul>
                          </div>
                        </ClickAwayListener>
                      )}

                      {/* {userData && ( */}
                      <>
                        <IconButton
                          onClick={handleWishList}
                          className="css-tooltip"
                        >
                          <FavoriteBorderIcon className="hover_icon" />
                          <span className="css-tooltip-title">WishList</span>
                        </IconButton>
                        <span className="css-tooltip">
                          <Carts />
                          <span className="css-tooltip-title">Your Cart</span>
                        </span>
                        <span className="css-tooltip">
                          <Notification />
                          <span className="css-tooltip-title">
                            Notifications
                          </span>
                        </span>
                      </>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AnnouncementBar />
      </Grid>
    </>
  );
};

export default memo(CategoryList);
