import React, { memo, useState } from "react";

import { useNavigate } from "react-router-dom";
import Responsiveheader from "./Responsiveheader";
import { CircularProgress, Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { Img_url } from "../../constant";

const Menu = () => {
  const [drower, setDrower] = useState(false);
  const categoryList = useSelector((state) => state.apiReducer.categoryList);

  const navigate = useNavigate();

  const handleClick = async (data) => {
    await navigate(`/subCategory/${data.id}`);
    setDrower(false);
  };
  return (
    <>
      <div>
        <Responsiveheader setDrower={setDrower} drower={drower} />
        <Drawer
          sx={{ zIndex: 99999 }}
          anchore="left"
          open={drower}
          onClose={() => setDrower(!drower)}
        >
          <nav className="nav_menu_actice">
            <ul className="nav_menu_item">
              {categoryList?.length !== 0 ? (
                <>
                  {categoryList.map((data, i) => (
                    <div key={i}>
                      <li
                        className="nav_menu_item_list pointer"
                        onClick={() => handleClick(data)}
                      >
                        <img
                          src={`${Img_url}${data.picture}`}
                          alt="img"
                          // className="card_img"
                          className="img-fluid me-2"
                          style={{ height: "20px " }}
                        />
                        {data.name}
                      </li>{" "}
                      <hr></hr>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {" "}
                  <CircularProgress color="secondary" />
                </>
              )}
            </ul>
          </nav>
        </Drawer>
      </div>
    </>
  );
};

export default memo(Menu);
