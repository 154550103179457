import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

// import { imgData } from "../../data/Data";

const Protabs = ({ description, image }) => {
  const [value, setValue] = React.useState("1");
  // console.log(description);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="pro_tabs" sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="pro_tabs"
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            className="  border_top_with_padding_in_product pb-3 pt-5"
          >
            <Tab label="Description" value="1" className="tab_btn " />
          </TabList>
        </Box>

        <div className="p-3">{description}</div>
      </TabContext>
    </Box>
  );
};
export default Protabs;
