import { Button, Grid } from "@mui/material";
import React, { memo } from "react";

import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const data = [
  {
    img: "	https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT06teTggVK5Z9hHv5DlQvm8qPm--wPiYoKBg&usqp=CAU",

    para: "Baby Diapers",
  },
  {
    img: "https://c8.alamy.com/comp/C5F5RC/packet-of-johnsons-baby-wipes-on-white-background-C5F5RC.jpg",
    para: "Baby wiper",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsHFgClYLruBI14Mt-5wIMQa-LWY4316Wjlg&usqp=CAU",
    para: "babycreams & lotions",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVHMwSDs_nDDKNc-Keme-jXso6niMNyLZ5Bw&usqp=CAU",
    para: "baby oils",
  },
  {
    img: "	https://ichef.bbci.co.uk/news/1024/cpsprodpb/1060E/production/_118768076_gettyimages-998003060.jpg",
    para: "baby powders",
  },
  {
    img: "https://m.media-amazon.com/images/I/612jsTFjR8L._SY450_.jpg",
    para: "baby soap",
  },
  {
    img: "	https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZYXiIpM7WRf5WBjACKxmxOKZmXKFySvEVOA&usqp=CAU",
    para: "feeding & nursing",
  },
  {
    img: "	https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW2aUON-0G7S-74GT7AhDmfHxW37h3B89u0w&usqp=CAU",
    para: "Baby Food",
  },
  {
    img: "https://www.foodsafetynews.com/files/2022/05/infant-formula.jpg",
    para: "infant formula",
  },
  {
    img: "https://www.tpci.in/indiabusinesstrade/wp-content/uploads/2021/11/TPCI_Indias-cereal-exports-.jpg",
    para: "cereals",
  },
];
const Personalcare = () => {
  const navigate = useNavigate();
  const getback = () => {
    navigate("/");
  };
  return (
    <>
      <div className="baby_div_show">
        <div className="btn_back_div">
          <Button onClick={getback} className="btn_baby_div">
            Back menu
          </Button>
          <ChevronRightIcon className="btn_baby_div" />
          <Button className="btn_baby_div">Personal care</Button>
        </div>

        <div className="row mt-3">
          {data.map((curent, ind) => {
            return (
              <div className="col-6 mt-3" key={ind}>
                <Grid className="upr_baby_img_div" key={ind}>
                  <div className="baby_img_div">
                    <img
                      src={curent.img}
                      alt="img"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    />
                  </div>
                  <p className="baby_para">{curent.para}</p>
                </Grid>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default memo(Personalcare);
