import { IconButton } from "@mui/material";
import React, { memo } from "react";
import "./Modal.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Heading2 } from "../../../components/heading/Heading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const HealthRecordModal = ({ open, setOpen, data }) => {
  return (
    <>
      <div
        className={
          open
            ? "overlay_bg_R open_cart  min_h_cart px-0"
            : "overlay_bg_R close_cart  min_h_cart px-0"
        }
      >
        <div className="d-flex align-items-center justify-content-between br_s_primary pb-2 ">
          <Heading2 classNames="ms-2" title="Prescription" />
          <IconButton
            sx={{ color: `var(--primary)` }}
            onClick={() => setOpen(!open)}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
        <div>
          {" "}
          <div className="container-fluid p-0">
            <TransformWrapper
              initialScale={1}
              initialPositionX={200}
              initialPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <button onClick={() => zoomIn()}>+</button>
                    <button onClick={() => zoomOut()}>-</button>
                    <button onClick={() => resetTransform()}>x</button>
                  </div>
                  <TransformComponent
                    wrapperStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                    contentStyle={{ width: "100%", height: "100%" }}
                  >
                    <img
                      className="card__img"
                      src={data}
                      alt="frontend-mentor-website-qrcode"
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HealthRecordModal);
