import React, { useState, useEffect, memo } from "react";
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import "./Header.scss";
import { SecondaryButton } from "../buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import CategoryList from "./CategoriesList";
import Fav from "../favourite/Fav";
import Menu from "./Menu";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import Carts from "./Carts";
import StoredVariables, {
  GetSession,
  SetSession,
} from "../../constant/Session";
import UserDropdown from "./UserDropdown";
import { Img_url } from "../../constant";
import { CategoryLists } from "../useCustomHooks/useFunctions";
import SearchBar from "./SearchBar";
import Notification from "./Notification";
import { useSelector } from "react-redux";

const Header = () => {
  CategoryLists();
  const [favopen, setFavOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [isUser, setIsuser] = useState(false);
  const [showDrop, setShowDrop] = useState(false);

  const userProfile = useSelector(
    (state) => state.userReducer.userProfile.data
  );
  // console.log(userProfile);

  useEffect(() => {
    setUserData(GetSession(StoredVariables.username));
  }, []);

  useEffect(() => {
    setUserData(GetSession(StoredVariables.username));
  }, [isUser]);

  const navigate = useNavigate();
  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);

  const handleWishList = () => {
    SetSession("activetabsMenu", 3);
    navigate("/loginDashboard");
  };

  return (
    <>
      <Grid sx={{ background: `var(--primary)` }}>
        <Grid sx={{ width: "98%" }} className="container  px-lg-3 pt-2">
          <Grid className="  p-0 h_on_sm ">
            <Grid className="row gx-0 align-items-center flex-nowrap">
              <Grid
                className=" d-flex flex-wrap align-items-center col-xs-12 py-2"
                item
                sx={{ width: "fit-content" }}
              >
                {" "}
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                  to="/"
                >
                  {isFrontImages && isFrontImages.length !== 0 ? (
                    <img
                      src={
                        isFrontImages &&
                        `${Img_url}${isFrontImages[1].credencial_content}`
                      }
                      alt="logo"
                      className="img-fluid"
                      width="125x"
                    />
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </Link>
              </Grid>
              <Grid item className="col-xs-10 flex-grow-1 w-auto py-2">
                <Grid
                  sx={{
                    // border: "1px solid rgba(0,0,0,.1)",
                    // paddingRight: "10px",
                    borderRight: "0px",
                  }}
                >
                  <div className=" d-flex flex-wrap justify-content-between align-items-center">
                    <div className="flex-grow-1 mx-lg-2 ps-3 ">
                      <SearchBar />
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <Link
                        style={{ textDecoration: "none", color: "#fff" }}
                        className="ps-2 pe-1 fs_15 fw_500 d-flex align-items-center show_num"
                        to="/pages/Contact"
                      >
                        Need help?
                        <WhatsAppIcon
                          className="ms-2 me-1 fs-5"
                          style={{
                            color: "#fff",
                            marginTop: "-1px",
                          }}
                        />
                        <span className="whatsapp_num">1234567890 </span>
                      </Link> */}
                      <div className=" d-flex flex-wrap justify-content-between align-items-center">
                        {/* <span
                          style={{ padding: "8px" }}
                          className="css-tooltip"
                        >
                          <Link to="/">
                            <HomeIcon sx={{ color: "#fff" }} />
                          </Link>
                          <span className="css-tooltip-title">Home</span>
                        </span> */}
                        <span
                          className="d-none big_screen_menu"
                          style={{ padding: "8px" }}
                          // className="css-tooltip"
                        >
                          {/* <UploadFileRoundedIcon
                            sx={{
                              color: "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => navigate("/upload")}
                          /> */}
                          <SecondaryButton
                            title="Upload Prescription"
                            endIcon={<UploadFileRoundedIcon />}
                            onClick={() => navigate("/upload")}
                          />
                          {/* <span className="css-tooltip-title">
                            Upload Prescription
                          </span> */}
                        </span>
                        {/* {userData !== null && userData !== "" && `${userData}`} */}
                        {(userData !== null && userData !== "") ||
                        userProfile ? (
                          <UserDropdown
                            userData={userData}
                            isUser={isUser}
                            setIsuser={setIsuser}
                          />
                        ) : (
                          <ClickAwayListener
                            onClickAway={() => setShowDrop(false)}
                          >
                            <div
                              className="dropdown_menu"
                              onClick={() => setShowDrop(true)}
                            >
                              <IconButton className="hover_it_drop hover_icon">
                                {/* {userData !== null && userData !== "" && `${userData}`} */}
                                <PersonIcon className="hover_icon" />
                              </IconButton>
                              <ul
                                className={` ${
                                  showDrop && "show_login_reg_dropdown"
                                } login_reg_dropdown d-flex  align-items-center mt-3 br_0 p-3 `}
                              >
                                <div className=" d-flex  align-items-center ">
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                    }}
                                    to="/login"
                                  >
                                    <div>
                                      <SecondaryButton
                                        title="Login"
                                        classNames="me-2 drop_btn"
                                      />
                                    </div>
                                  </Link>
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                    }}
                                    to="/register"
                                  >
                                    <SecondaryButton title="Register" />
                                  </Link>
                                </div>
                              </ul>
                            </div>
                          </ClickAwayListener>
                        )}
                        {/* {userData && ( */}
                        <>
                          <IconButton
                            onClick={handleWishList}
                            className="css-tooltip"
                          >
                            <FavoriteBorderIcon className="hover_icon" />
                            <span className="css-tooltip-title">WishList</span>
                          </IconButton>

                          <span className="css-tooltip">
                            <Carts />
                            <span className="css-tooltip-title">Your Cart</span>
                          </span>
                          <span className="css-tooltip">
                            <Notification />
                            <span className="css-tooltip-title">
                              Notifications
                            </span>
                          </span>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="h_on_lg">
            <Menu />
          </Grid>
        </Grid>
        <Fav open={favopen} setOpen={setFavOpen} />{" "}
      </Grid>
      <CategoryList userData={userData} isUser={isUser} setIsuser={setIsuser} />
    </>
  );
};

export default memo(Header);
