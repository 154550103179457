import React, { memo, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAuthentication } from "../../constant/RequestAuthService";
import {
  FeatureProducts,
  NewAllProduct,
  NextFeaturedpageUrl,
  NextpageUrl,
} from "../../constant/Util";

import axios from "axios";
import {
  MoreFeatureProductReload,
  MoreProductReload,
  RemoveSendData,
  useCartFunctanilty,
} from "../../components/useCustomHooks/useFunctions";
import InfiniteScroll from "react-infinite-scroll-component";

import { ProductCardLoader } from "../../components/skeletons/Loader";
import ProductCard from "../../components/productCard/ProductCard";

const CategoryFilter = () => {
  const [searchparams] = useSearchParams();
  const isFeatured = searchparams.get("is_featured");
  const dispatch = useDispatch();

  const moreProduct =
    isFeatured * 1 === 1 ? FeatureProducts() : NewAllProduct();
  const nextPageUrl =
    isFeatured * 1 === 1 ? NextFeaturedpageUrl() : NextpageUrl();

  const { AddToCart, Increment, Decrement, DeleteCart, HandlewishtList } =
    useCartFunctanilty();

  // console.log(nextPageUrl);

  const hasMore = true;
  const loading = useSelector((state) => state.cartsReducer.moreProductLoad);

  // console.log(moreProduct);

  MoreProductReload();
  MoreFeatureProductReload();
  RemoveSendData();

  const fetchMoredata = () => {
    setTimeout(() => {
      getallNextProduct(nextPageUrl);
    }, 500);
  };

  const getallNextProduct = async (nextPageUrl) => {
    const requestData = {};
    if (isFeatured * 1 === 1) {
      requestData.is_fetured = 1;
    }

    if (
      nextPageUrl !== null &&
      nextPageUrl !== "null" &&
      nextPageUrl.length !== 0
    ) {
      const { status, data } = await axios.post(nextPageUrl, requestData, {
        headers: {
          Authorization: `Bearer ${GetAuthentication(true).token}`,
          "Content-type": "application/json",
        },
      });
      if (status === 200) {
        // console.log(data.data);
        dispatch({
          type: "AddProductinallProduct",
          payload: data.data,
        });
      }
    }
  };

  // console.log(isFrontImages);

  // console.log(cartItemSend.items.find((product) => product.id === 3));

  // const Increment = async (id) => {
  //   // console.log(id);
  //   dispatch({ type: "UpdateData", payload: id });
  //   await dispatch({ type: "IsProductAdded" });
  //   setFetch("fetch");
  //   setTimeout(() => {
  //     setFetch("");
  //   }, 300);
  // };

  // const decrement = async (id) => {
  //   console.log(id);
  //   dispatch({ type: "DegradeData", payload: id });
  //   await dispatch({ type: "IsProductAdded" });
  //   setFetch("fetch");
  //   setTimeout(() => {
  //     setFetch("");
  //   }, 300);
  // };

  // console.log(moreProduct);

  const getValues = useSelector((state) => state.cartsReducer.getAllvalues);
  // console.log(allProduct);

  useEffect(() => {
    const prAdd = async () => {
      await dispatch({ type: "IsProductAdded" });
    };
    prAdd();
  }, [getValues]); // eslint-disable-line react-hooks/exhaustive-deps
  //   console.log(allProduct);

  const Loader = () => {
    return (
      <div className="container">
        <div className="row">
          {[...Array(8).keys()].map((data, i) => (
            <div key={i} className=" col-md-4 col-xl-3 col-xxl-2 p-0 mb-2 mt-2">
              <ProductCardLoader />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const HtmlPage = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row gx-4 px-3 px-sm-0">
            {loading ? (
              <>
                {[...Array(4).keys()].map((data, i) => (
                  <div key={i} className="col-md-4 col-xl-3 col-xxl-2 p-0">
                    <ProductCardLoader />
                  </div>
                ))}
              </>
            ) : (
              <>
                {moreProduct.map((data, i) => (
                  <div
                    key={i}
                    className="col-md-4 col-xl-3 col-xxl-2 py-2 px-0 p-md-3"
                  >
                    {/* <Grid className="top_offer_card br_8 ">
                      <Grid
                        onClick={() => navigate(`/productDetails/${data.id}`)}
                      >
                        <Grid className="mx-2">
                          {data.picture !== null ? (
                            <img
                              src={`${Img_url}${data.picture}`}
                              className={`img-fluid top_offer_card_inner_div br_8 ${
                                cartItemSend.items.find(
                                  (product) => product.id === data.id
                                ) && "alpha"
                              } `}
                              alt="top_offer"
                              style={{
                                height: "150px",
                                display: "block",
                                margin: "10px auto",
                              }}
                            />
                          ) : (
                            <>
                              {isFrontImages ? (
                                <img
                                  src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                  className={`img-fluid top_offer_card_inner_div br_8 ${
                                    cartItemSend.items.find(
                                      (product) => product.id === data.id
                                    ) && "alpha"
                                  } `}
                                  alt="top_offer"
                                  style={{
                                    height: "150px",
                                    display: "block",
                                    margin: "10px auto",
                                  }}
                                />
                              ) : (
                                <CircularProgress color="secondary" />
                              )}
                            </>
                          )}
                        </Grid>
                        <p className="mt-2  mx-3 fs_14 fw_500">{data.name}</p>
                        <p className="mt-2 mx-3  fs_15 fw_700 ">{data.para}</p>
                        <div className="d-flex justify-content-between align-items-center mt-2 mx-3 pb-3">
                          {data?.Mrp && (
                            <p className="  primary_col fs_14 fw_600">
                              Rs {data?.Mrp}
                            </p>
                          )}
                        </div>
                      </Grid>
                      <Grid className="left_div_top_offer">
                        <br />

                        <IconButton
                          onClick={() => handlewishtList(data.id)}
                          className="top_oofer_icon"
                        >
                          {getSavedWishList &&
                          getSavedWishList.find(
                            (item) => item.product_id === data.id
                          ) ? (
                            <FavoriteIcon sx={{ color: `var(--secondary)` }} />
                          ) : (
                            <FavoriteBorderIcon />
                          )}
                        </IconButton>
                        <br />
                        <IconButton className="top_oofer_icon">
                          <ShareIcon />
                        </IconButton>
                      </Grid>

                      <Grid className="right_div_top_offer">
                        {data.is_fetured === 1 && (
                          <StarRateIcon sx={{ color: "#E6A300" }} />
                        )}
                        {cartItemSend.items.find(
                          (product) => product.id === data.id
                        ) && (
                          <Grid className="mt-2">
                            <TaskAltIcon sx={{ color: "var(--primary)" }} />
                          </Grid>
                        )}
                      </Grid>
                      <Grid className="w-100 mb-4 d-flex align-items-center justify-content-start">
                        {!data?.Mrp ? (
                          <>
                            <Button
                              variant="contained"
                              disabled
                              className=" mx-3 w-100"
                            >
                              Not Available
                            </Button>
                          </>
                        ) : (
                          <>
                            {!cartItemSend.items.find(
                              (product) => product.id === data.id
                            ) ? (
                              <PrimaryButton
                                onClick={() => addToCart(data)}
                                title="Add To Cart"
                                classNames=" mx-3 w-100"
                              />
                            ) : (
                              <div className="d-flex">
                                <Grid className="d-flex align-items-center">
                                  <IconButton
                                    onClick={() => decrement(data.id)}
                                    className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                  >
                                    -
                                  </IconButton>
                                  <Typography className="px-2 fs-7 fw-bold">
                                    {data.finalQuantity}
                                  </Typography>
                                  <IconButton
                                    onClick={() => Increment(data)}
                                    className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                  >
                                    +
                                  </IconButton>
                                </Grid>
                                <SecondaryButton
                                  onClick={() => deleteCart(data.id)}
                                  title="Remove"
                                  classNames=" w-100"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid> */}
                    <ProductCard
                      data={data}
                      HandlewishtList={HandlewishtList}
                      AddToCart={AddToCart}
                      Increment={Increment}
                      Decrement={Decrement}
                      DeleteCart={DeleteCart}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container mt-3">
        {moreProduct ? (
          <>
            {nextPageUrl !== null ? (
              <>
                <InfiniteScroll
                  dataLength={moreProduct.length}
                  next={fetchMoredata}
                  hasMore={hasMore}
                  loader={<Loader />}
                >
                  <HtmlPage />
                </InfiniteScroll>
              </>
            ) : (
              <>
                <HtmlPage />
              </>
            )}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <CircularProgress color="secondary" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(CategoryFilter);
