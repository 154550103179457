import React, { memo, useEffect } from "react";
import Slider from "./Slider";
import BestSellers from "./bestSellers/BestSellers";
import Banner from "./Banner";
// import ShopbyCategory from "./shopbycategory/ShopbyCategory";
import TopOffers from "./topOffers/TopOffers";
import { Img_url } from "../../constant";
import WhyChooseUs from "./whychooseus/WhyChooseUs";
import { useDispatch, useSelector } from "react-redux";
import { RemoveSendData } from "../../components/useCustomHooks/useFunctions";
import { GET } from "../../constant/RequestAuthService";
import AfterLoginCards from "./AfterLoginCards/AfterLoginCards";
import StoredVariables, {
  GetSession,
  SetSession,
} from "../../constant/Session";
import { Reload } from "../../constant/Util";

export const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

const Home = () => {
  const dispatch = useDispatch();
  RemoveSendData();
  const getBanner = useSelector((state) => state.apiReducer);

  const handleBanner = async () => {
    const response1 = await GET("banners");
    if (response1.status === 200) {
      const ban = [...response1.data];

      const arr2 = [];

      let ind = 0,
        max = ban[0],
        min = ban[0];

      for (let j = 0; j < ban.length; j++) {
        if (max.is_order < ban[j].is_order) {
          max = ban[j];
        }
      }

      for (let i = 0; i < ban.length; i++) {
        for (let k = 0; k < ban.length; k++) {
          if (ban[k] !== null) {
            if (min.is_order > ban[k].is_order) {
              min = ban[k];
              ind = k;
            }
          }
        }
        arr2[i] = min;

        ban[ind] = null;
        min = max;
      }

      dispatch({ type: "getBanner", payload: arr2 });
    }
  };

  useEffect(() => {
    if (getBanner.banner && getBanner.banner.length === 0) {
      handleBanner();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const banner1 = getBanner.banner.slice(1, 3);
  const banner2 = getBanner.banner.slice(4, 6);
  const banner3 = getBanner.banner.slice(7, 11);

  const isLogin = GetSession(StoredVariables.username);

  const getProfile = async () => {
    const profile = await GET("profile");
    // console.log("run");
    if (profile.status === 200) {
      await dispatch({ type: "userProfile", payload: profile });
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (
      GetSession(StoredVariables.reload) &&
      GetSession(StoredVariables.reload) === "true"
    ) {
      SetSession(StoredVariables.reload, false);
      Reload();
    }
  }, []);

  const userProfile = useSelector(
    (state) => state.userReducer.userProfile.data
  );

  useEffect(() => {
    if (isLogin && !userProfile) {
      getProfile();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="">
        <Slider />
        {getBanner.banner && getBanner.banner.length > 0 && (
          <div className=" mt-3 container">
            <Banner src={`${Img_url}${getBanner.banner[0].image}`} />
          </div>
        )}
        {isLogin && <AfterLoginCards />}

        {/* <ShopbyCategory /> */}
        {banner1 && banner1.length > 0 && (
          <div className="container mt-3  ">
            <div className="row">
              {banner1 && banner1.length > 0
                ? banner1.map((x, i) => (
                    <div key={i} className="col-sm-6 pt-3">
                      <div>
                        <Banner src={`${Img_url}${x.image}`} />
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        )}

        <TopOffers />
        {banner2 && banner2.length > 0 && (
          <div className="container d-flex mt-3">
            <div className="row">
              {banner2 && banner2.length > 0
                ? banner2.map((x, i) => (
                    <div key={i} className="col-sm-6 pt-3">
                      <div>
                        <Banner src={`${Img_url}${x.image}`} />
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        )}

        <BestSellers />
        {banner3 && banner3.length > 0 && (
          <div className="container">
            <div
              style={{ gap: "20px" }}
              className=" mt-3 d-flex align-items-start  justify-content-between flex-wrap flex-md-row flex-column "
            >
              {banner3 && banner3.length > 0
                ? banner3.map((x, i) => (
                    <div key={i} className=" flex-even mx-auto">
                      <div className="border_icons_cat h-100 d-flex align-items-center  ">
                        <img
                          style={{ height: "100px" }}
                          src={`${Img_url}${x.image}`}
                          alt="banner"
                          className={`img-fluid mx-auto `}
                        />
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        )}

        <WhyChooseUs />
      </div>
    </>
  );
};

export default memo(Home);
