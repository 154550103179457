import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
// import ScrollToTop from "react-scroll-to-top";
import App from "./App";
import "./Global.scss";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { RouterTop } from "./scrolltoTop/ScrollToTop";
import Maintenance from "./components/maintenanceMode/Maintenance";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer theme="dark" />
    <Router>
      <Maintenance />
      <RouterTop />
      {/* <ScrollToTop smooth component={<ScrollIcon />} /> */}
      <App />
    </Router>
  </Provider>
);
