import React, { useState } from "react";

import UploadFile from "@mui/icons-material/UploadFile";
import { Heading2 } from "../../components/heading/Heading";
import { Button, IconButton } from "@mui/material";
import Del from "../../assets/images/Del.png";
import { LoaderButton, SecondaryButton } from "../../components/buttons/Button";
import notav from "../../assets/images/notav.png";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import { POST } from "../../constant/RequestAuthService";
import { useNavigate } from "react-router-dom";
import { SetSession } from "../../constant/Session";

const NotAvailable = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [images, setImages] = useState();
  const [inputs, setInputs] = React.useState({
    product: "",
    Mrp: "0.00",
    details: "",
    company: "",
    bill: "0.00",
  });
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setImages(e.target.files[0]);
  }

  const handleCancel = () => {
    setFile(null);
    setImages(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const checkdata = {
      name: "required",
    };

    const requestData = {
      name: inputs.product,
      company_name: inputs.company,
      mrp: inputs.Mrp,
      bill_price: inputs.bill_price,
      detail: inputs.details,
    };

    const validation = new Validator(requestData, checkdata);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);
    } else {
      const uploadData = new FormData();
      uploadData.append("name", inputs.product);
      uploadData.append("company_name", inputs.company);
      uploadData.append("mrp", inputs.Mrp);
      uploadData.append("bill_price", inputs.bill);
      uploadData.append("detail", inputs.details);
      if (images) {
        uploadData.append("image", images);
      }

      const response = await POST("request/add", uploadData);
      // console.log(response);
      if (response.status === 200) {
        toast.success(response.message);
        setInputs({ product: "", Mrp: "", details: "", company: "", bill: "" });
        setFile(null);
        setImages(null);
        SetSession("activetabsMenu", 5);
        navigate("/loginDashboard");
        setLoading(false);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container mt-3 pb-0 flex-grow-1 ">
        <div className="row">
          <div
            style={{ maxHeight: "55vh" }}
            className="col-sm-6 d-flex align-items-center justify-content-center"
          >
            <img
              src={notav}
              alt="log"
              className="img-fluid"
              style={{
                maxHeight: "50vh",

                borderRadius: "8px",
              }}
            />
          </div>
          <div className="col-sm-6 d-flex align-items-center justify-content-center flex-column ">
            <form onSubmit={handleSubmit} className="w-100">
              <input
                name="product"
                type="text"
                value={inputs.product}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Product Name *"
                className="login_field mt-4"
              />
              <input
                name="company"
                type="text"
                value={inputs.company}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Company Name (optional)"
                className="login_field mt-4"
              />
              <input
                name="Mrp"
                type="number"
                value={inputs.Mrp}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Mrp (optional)"
                className="login_field  mt-4"
              />
              {/* <input
                name="bill"
                type="number"
                value={inputs.bill}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Bill Price (optional)"
                className="login_field  mt-4"
              /> */}
              <textarea
                name="details"
                type="text"
                value={inputs.details}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="More details (optional)"
                className="login_field mt-4"
              />
              <div className=" d-flex align-items-center justify-content-center flex-column top_offer_card  mt-3 br_8 py-4 login_field">
                {!file ? (
                  <div className=" d-flex align-items-center justify-content-center flex-column ">
                    <UploadFile fontSize="large" />
                    <Heading2
                      title="Upload Product Image"
                      classNames="text-center py-3"
                    />
                    <Button component="label" className={`outline_btn`}>
                      Upload
                      <input
                        onChange={handleChange}
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                      />
                    </Button>{" "}
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-between w-100  pb-3 align-items-center">
                      <IconButton onClick={handleCancel}>
                        <img src={Del} alt="delete" width="30px" />
                      </IconButton>
                    </div>
                    <img src={file} alt="file" className="img-fluid" />
                  </>
                )}
              </div>
              {loading ? (
                <>
                  <div className="d-flex align-items-center justify-content-center login_btns mt-2">
                    <LoaderButton title="loading" />
                  </div>
                </>
              ) : (
                <SecondaryButton
                  title="Submit"
                  classNames="mt-4 mb-4  login_btns"
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAvailable;
