import React from "react";
import "./Drop.scss";
import { Img_url } from "../../constant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SetSession } from "../../constant/Session";
import { Stringify } from "../../constant/Util";
import { SecondaryButton } from "../buttons/Button";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";

const Drop = ({ headers }) => {
  const navigate = useNavigate();
  const AllHeaderMenus = useSelector((state) => state.apiReducer.HeaderMenus);

  const SubCategoryProduct = (data) => {
    SetSession("C_ID", Stringify(data.category_id));
    navigate(`/subCategories/${data.id}/${data.category_id}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="wrapper">
          <nav>
            <div className="content">
              <ul className="links p-0">
                <li>
                  <div
                    className={`text-white d-flex align-items-center justify-content-center ${
                      headers && "d-none"
                    }`}
                  >
                    <p
                      style={{ cursor: "pointer" }}
                      className="px-2 fs_15 fw_500"
                    >
                      All Categories
                    </p>
                    <ExpandMoreIcon className="icon_cat_rot" />
                  </div>

                  <ul className="ui_list_1 p-0 b_bottom_p_primary">
                    {AllHeaderMenus && AllHeaderMenus.length > 0
                      ? AllHeaderMenus.map((data, ind) => (
                          <li key={ind}>
                            <Link
                              to={`/subCategory/${data.id}`}
                              className="dropdown-item"
                            >
                              <span className="mr-1">
                                <img
                                  src={`${Img_url}${data.picture}`}
                                  alt="img"
                                  // className="card_img"
                                  className="img-fluid me-2"
                                  width="20px"
                                />
                              </span>
                              {data.name}
                            </Link>
                            <div className="arrow-left"></div>
                            <ul
                              style={{ maxHeight: "60vh", overflowY: "auto" }}
                              className="more_list p-0 "
                            >
                              {data.sub_category.map((x, i) => (
                                <li
                                  className="px-2 pointer"
                                  key={i}
                                  onClick={() => SubCategoryProduct(x)}
                                >
                                  {/* <Link to={`/subCategories/${x.id}`}> */}
                                  {x.name}
                                  {/* </Link> */}
                                </li>
                              ))}

                              {/* <li>
                              <a href="#hgfh">Baby wiper</a>
                            </li>
                            <li>
                              <a href="#hgfh">babycreams & lotions</a>
                            </li>
                            <li>
                              <a href="#hgfh">baby oils</a>
                            </li>
                            <li>
                              <a href="#hgfh">baby powders</a>
                            </li>
                            <li>
                              <a href="#hgfh">baby soap</a>
                            </li>
                            <li>
                              <a href="#hgfh">feeding & nursing</a>
                            </li>
                            <li>
                              <a href="#hgfh">baby nutrising</a>
                            </li>
                            <li>
                              <a href="#hgfh" className="bold_font mt-2">
                                Baby Food
                              </a>
                            </li>
                            <li>
                              <a href="#hgfh">infant formula</a>
                            </li>
                            <li>
                              <a href="#hgfh">cereals</a>
                            </li> */}
                            </ul>
                          </li>
                        ))
                      : ""}
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <SecondaryButton
          title="Upload Prescription"
          endIcon={<UploadFileRoundedIcon />}
          onClick={() => navigate("/upload")}
        />
      </div>
    </>
  );
};

export default Drop;
