import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { Img_url } from "../../constant";
import { IconButton } from "@mui/material";
import { POST } from "../../constant/RequestAuthService";
import { GetSession, SetSession } from "../../constant/Session";
import { Json, Stringify } from "../../constant/Util";

const SmScreenSearch = () => {
  const [name, setName] = useState(" All Categories");
  const [search, setSearch] = useState("");
  const [catDropSmall, setCatDropSmall] = useState(false);
  const dispatch = useDispatch();
  const [sendData, setSendData] = useState();
  const [category_id, setCategory_id] = useState(null);
  const [sub_category_id, setSub_category_id] = useState(null);
  const [isData, setIsData] = useState(false);

  const prevSearch = Json(GetSession("sendData"));
  //   console.log(prevSearch);

  useEffect(() => {
    if (prevSearch !== null) {
      if (
        prevSearch.search !== "" ||
        prevSearch.category_id !== "" ||
        prevSearch.sub_category_id !== ""
      ) {
        setSendData(prevSearch);
        setSearch(prevSearch.search);
        setCategory_id(prevSearch.category_id);
        setName(prevSearch.name);
        setSub_category_id(prevSearch.sub_category_id);
        setIsData(false);
        setTimeout(() => {
          setIsData(true);
        }, 300);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handelCatDrop = () => {
    setCatDropSmall(!catDropSmall);
    // console.log(catDropSmall);
  };

  const display = (data) => {
    setName(data.name);
    setCategory_id(data.id);
    setCatDropSmall(false);
  };

  const displayall = () => {
    setName("All Categories");
    setCategory_id(null);
    setCatDropSmall(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("run");
    if (search === "") {
      return;
    } else {
      dispatch({ type: "loadingtrue" });

      const response = await POST("products", sendData);

      if (response.status === 200) {
        dispatch({ type: "loadingfalse" });
        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        SetSession("sendData", Stringify({ ...sendData, name }));
      }

      dispatch({ type: "loadingfalse" });
      // console.log(category_id, input);
    }
    dispatch({ type: "loadingfalse" });
  };
  const handleSearchi = async () => {
    console.log("run");
    if (search === "") {
      return;
    } else {
      dispatch({ type: "loadingtrue" });

      const response = await POST("products", sendData);

      if (response.status === 200) {
        dispatch({ type: "loadingfalse" });
        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        SetSession("sendData", Stringify({ ...sendData, name }));
      }

      dispatch({ type: "loadingfalse" });
      // console.log(category_id, input);
    }
    dispatch({ type: "loadingfalse" });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (search.length > 2) {
        handleSearchi();
      }
      if (search.length === 0) {
        dispatch({ type: "emptySearchProduct" });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [search,sendData]); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryList = useSelector((state) => state.apiReducer);

  useEffect(() => {
    setSendData({ category_id, search, sub_category_id });
  }, [category_id, search, sub_category_id]);

  useEffect(() => {
    if (isData === true) {
      if (prevSearch !== "undefined") {
        const handleReload = async () => {
          // console.log(true);
          dispatch({ type: "ReloadLoadingStart" });
          const response = await POST("products", sendData);
          if (response.status === 200) {
            dispatch({
              type: "getSearchProduct",
              payload: response.data,
            });
            dispatch({ type: "ReloadLoadingStop" });
          }
        };
        handleReload();
      }
      dispatch({ type: "LoadingStopProduct" });
    }
  }, [isData]); // eslint-disable-line react-hooks/exhaustive-deps

  //   console.log(name);

  return (
    <div className="container">
      <div className="cat_search">
        <div className="d-flex align-items-center justify-content-between">
          <div
            onClick={handelCatDrop}
            className="flex-grow-1 search_input_field2 mt-4"
            style={{
              padding: "10px ",
              border: " 2px solid var(--primary)",
            }}
          >
            {name}
          </div>
        </div>
        <div className={catDropSmall ? "cat-drop" : "d-none"}>
          <ul className="cat-drop-list">
            <li onClick={displayall}>All Categories</li>
            {categoryList && categoryList.categoryList.length > 0
              ? categoryList.categoryList.map((data, i) => (
                  <li key={i} onClick={() => display(data)}>
                    <img src={`${Img_url}${data.picture}`} alt="" />
                    <p> {data.name}</p>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div className="search_relat">
        <form onSubmit={(e) => handleSearch(e)}>
          <input
            className="search_bar_reas"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <IconButton type="submit" className="serch_abslut">
            <SearchIcon />
          </IconButton>
        </form>
      </div>
    </div>
  );
};

export default SmScreenSearch;
