import React, { memo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";

import {
  FeatureProductReload,
  // HomeProductReload,
  IsProductAdded,
  useCartFunctanilty,
} from "../../../components/useCustomHooks/useFunctions";
import { ProductCardLoader } from "../../../components/skeletons/Loader";
import ProductCard from "../../../components/productCard/ProductCard";
import { Heading2 } from "../../../components/heading/Heading";
import { useEffect } from "react";

const ProductCarousel = ({ data }) => {
  const [allProduct, setAllProduct] = useState("");
  useEffect(() => {
    setAllProduct(data);
  }, [data]);
  // console.log(allProduct);
  const { AddToCart, Increment, Decrement, DeleteCart, HandlewishtList } =
    useCartFunctanilty();
  // HomeProductReload();
  IsProductAdded();
  FeatureProductReload();

  // console.log(allProduct);

  return (
    <>
      <div className="mt-3">
        {allProduct && typeof allProduct === "object" ? (
          allProduct.length !== 0 ? (
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay={false}
              autoPlaySpeed={4000}
              className=""
              containerclassName="container-with-dots"
              dotListclassName=""
              draggable
              focusOnSelect={false}
              infinite
              itemclassName=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                bdesktop: {
                  breakpoint: {
                    max: 2600,
                    min: 1440,
                  },
                  items: 6,
                  partialVisibilityGutter: 40,
                },
                desktop: {
                  breakpoint: {
                    max: 1440,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                bigTablet: {
                  breakpoint: {
                    max: 1024,
                    min: 769,
                  },
                  items: 3,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 768,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              // showDots
              sliderclassName=""
              slidesToSlide={2}
              swipeable
            >
              {allProduct.map((data, id) => (
                <Grid className="px-3" key={id}>
                  <ProductCard
                    data={data}
                    HandlewishtList={HandlewishtList}
                    AddToCart={AddToCart}
                    Increment={Increment}
                    Decrement={Decrement}
                    DeleteCart={DeleteCart}
                  />
                </Grid>
              ))}
            </Carousel>
          ) : (
            <Heading2 title="No Product at the moment" />
          )
        ) : (
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={4000}
            centerMode={false}
            className=""
            containerclassName="container-with-dots"
            dotListclassName=""
            draggable
            focusOnSelect={false}
            infinite
            itemclassName=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 26000,
                  min: 1024,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              bigTablet: {
                breakpoint: {
                  max: 1024,
                  min: 769,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 768,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            // showDots
            sliderclassName=""
            slidesToSlide={2}
            swipeable
          >
            {[...Array(10).keys()].map((data, i) => (
              <div key={i}>
                <ProductCardLoader />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default memo(ProductCarousel);
