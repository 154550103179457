import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../healthrecords/HealthRecords.scss";
import { Heading1, Heading2 } from "../../../components/heading/Heading";
import { GET } from "../../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import { Img_url } from "../../../constant";
import { OutlineButton } from "../../../components/buttons/Button";
import health_cr from "../../../assets/images/health_cr.png";
import "./ProductRequest.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ProductRequest = () => {
  const navigate = useNavigate();
  const myRequestProduct = useSelector(
    (state) => state.userReducer.requestProduct
  );

  const dispatch = useDispatch();

  useEffect(() => {
    handlegetRequestData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlegetRequestData = async () => {
    const response1 = await GET("request/get");
    if (response1.status === 200) {
      dispatch({ type: "SavedProductRequest", payload: response1.data });
    }
  };

  // console.log(myRequestProduct);
  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  return (
    <>
      <div className="d-flex flex-column">
        <div className="container">
          <div>
            {myRequestProduct && typeof myRequestProduct === "object" ? (
              <>
                {myRequestProduct.length === 0 ? (
                  <>
                    <div className="d-flex align-items-center justify-content-between flex-wrap  mb-3">
                      <Heading2
                        title="Don't have request yet"
                        classNames={"my-2"}
                      />
                      <OutlineButton
                        classNames={"my-2"}
                        endIcon={
                          <img
                            src={health_cr}
                            alt="health_cr"
                            width="20px"
                            style={{ marginTop: "-3px" }}
                          />
                        }
                        title="Create New Product Request"
                        onClick={() => navigate("/moreDetails")}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 border_bottom_with_padding_in_product pb-4">
                      <Heading1
                        title="My Product Request"
                        classNames={"my-2"}
                      />
                      <OutlineButton
                        classNames={"my-2"}
                        endIcon={
                          <img
                            src={health_cr}
                            alt="health_cr"
                            width="20px"
                            style={{ marginTop: "-2px" }}
                          />
                        }
                        title="Create New Product Request"
                        onClick={() => navigate("/moreDetails")}
                      />
                    </div>
                    {myRequestProduct.map((data, i) => (
                      // <div key={i} className="col-sm-6 col-lg-4 col-xl-3">
                      //   <article className="card mb-3">
                      //     <img
                      //       className="card__img"
                      //       src={`${Img_url}${data.image}`}
                      //       alt="frontend-mentor-website-qrcode"
                      //     />
                      //     {data.isShow !== true ? (
                      //       <div>
                      //         <div className="card__content">
                      //           <Heading2
                      //             title="Records"
                      //             classNames="mt-3 mb-2"
                      //           />

                      //           <p className="fs_15 fw_500 ">
                      //             Product name :{" "}
                      //             <span
                      //               className="fs_12"
                      //               style={{ color: `var(--dim_txt)` }}
                      //             >
                      //               {data.name}
                      //             </span>
                      //           </p>
                      //           <p className="fs_15 fw_500 ">
                      //             Company name :{" "}
                      //             <span
                      //               className="fs_12"
                      //               style={{ color: `var(--dim_txt)` }}
                      //             >
                      //               {data.company_name}
                      //             </span>
                      //           </p>
                      //           <p className="fs_15 fw_500 ">
                      //             Mrp :{" "}
                      //             <span
                      //               className="fs_12"
                      //               style={{ color: `var(--dim_txt)` }}
                      //             >
                      //               {data.mrp}
                      //             </span>
                      //           </p>
                      //           <p className="fs_15 fw_500 ">
                      //             Detail :{" "}
                      //             <span
                      //               className="fs_12"
                      //               style={{ color: `var(--dim_txt)` }}
                      //             >
                      //               {data.detail}
                      //             </span>
                      //           </p>
                      //           <p className="fs_15 fw_500 ">
                      //             Status :{" "}
                      //             <span
                      //               className="fs_12 fw_700"
                      //               style={{
                      //                 color: `${
                      //                   data.status_id === 1
                      //                     ? "var(--primary)"
                      //                     : data.status_id === 2
                      //                     ? "var(--secondary)"
                      //                     : ""
                      //                 }`,
                      //               }}
                      //             >
                      //               {data.status_id === 1
                      //                 ? "Active"
                      //                 : data.status_id === 2
                      //                 ? "Close"
                      //                 : ""}
                      //             </span>
                      //           </p>
                      //         </div>
                      //       </div>
                      //     ) : (
                      //       ""
                      //     )}
                      //   </article>
                      // </div>

                      <div key={i} className="order_main_div request_list mt-3">
                        <div className="d-flex align-items-start product_img_div gap-3 p-2">
                          {data.image !== null ? (
                            <Zoom>
                              <img
                                alt="details"
                                src={`${Img_url}${data.image}`}
                                width="80px"
                                className="list-img"
                              />
                            </Zoom>
                          ) : (
                            <>
                              {" "}
                              {isFrontImages ? (
                                <>
                                  {" "}
                                  <Zoom>
                                    <img
                                      alt="details"
                                      src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                      width="80px"
                                      className="list-img"
                                    />
                                  </Zoom>
                                </>
                              ) : (
                                <CircularProgress color="secondary" />
                              )}
                            </>
                          )}

                          {/* <img
                            src={`${Img_url}${data.image}`}
                            alt="Img"
                            width="80px"
                            className="list-img"
                          /> */}
                          <div className="ps_mxw_499 text-right-mxw">
                            <p className="order_item_main_head">{data.name}</p>
                            <p className="order_item_Sec_head mt-1">
                              paracitamol
                            </p>
                          </div>
                        </div>
                        <div className="p-2">
                          <p className="order_item_main_head">Company name</p>
                          <p className="order_item_Sec_head mt-1">
                            {data.company_name}
                          </p>
                        </div>
                        <div className="p-2 text-right-mxw">
                          <p className="order_item_main_head">MRP</p>
                          <p className="order_item_Sec_head mt-1">
                            Rs {data.mrp}
                          </p>
                        </div>
                        <div className="p-2">
                          <p className="order_item_main_head">Details</p>
                          <p className="order_item_Sec_head mt-1">
                            {data.detail}
                          </p>
                        </div>
                        <div className="p-2 text-right-mxw">
                          <p className="order_item_main_head">Status</p>
                          <p
                            className="order_item_Sec_head mt-1 fw_700"
                            style={{
                              color: `${
                                data.status_id === 1
                                  ? "var(--primary)"
                                  : data.status_id === 2
                                  ? "var(--secondary)"
                                  : ""
                              }`,
                            }}
                          >
                            {data.status_id === 1
                              ? "Active"
                              : data.status_id === 2
                              ? "Close"
                              : ""}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRequest;
