import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import StoredVariables, { GetSession } from "../../constant/Session";
import { DECODE } from "../../constant/Util";

export const Maintenance = () => {
  const [text, setText] = useState("");
  useEffect(() => {
    const maintenanceTag = GetSession(StoredVariables.maintenance);
    setText(maintenanceTag);
    // console.log(maintenanceTag);
  }, []);
  // console.log(typeof DECODE(text) === "object");

  return (
    <>
      {text ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            background: `var(--primary)`,
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "99999999",
          }}
          className="d-flex align-items-center justify-content-center p-2"
        >
          <div style={{color:"#fff", fontSize:"1.4rem"}} dangerouslySetInnerHTML={{ __html: DECODE(text) }}></div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
