import { createReducer } from "@reduxjs/toolkit";

let userProfilexist = sessionStorage.getItem("userProfile");

const initialState = {
  userProfile: (userProfilexist && JSON.parse(userProfilexist)) || {},
  loading: false,
  userCart: [],
  userAddress: [],
  wishList: [],
  orders: null,
  records: null,
  filterOrders: [],
  requestProduct: null,
};
// const path = useLocation();

const userReducer = createReducer(
  initialState,

  {
    userProfile: (state, action) => {
      state.userProfile = action.payload;
      sessionStorage.setItem("userProfile", JSON.stringify(state.userProfile));
      state.loading = false;
    },
    UserSavedCart: (state, action) => {
      state.userCart = action.payload;
    },
    SavedWishList: (state, action) => {
      state.wishList = action.payload;
    },
    SavedUserAddress: (state, action) => {
      state.userAddress = action.payload;
    },
    SavedOrder: (state, action) => {
      if (action.payload === null) {
        state.orders = [];
      } else {
        state.orders = action.payload;
      }
    },
    SavedProductRequest: (state, action) => {
      if (action.payload === null) {
        state.requestProduct = [];
      } else {
        state.requestProduct = action.payload;
      }
    },
    SavedRecords: (state, action) => {
      if (action.payload === null) {
        state.records = [];
      } else {
        state.records = action.payload;
      }
    },
    PresCancel: (state, action) => {
      const newRecords = state.records.filter(
        (data) => data.id !== action.payload.id
      );
      state.records = newRecords;
    },
    FilterOrderStatus: (state, action) => {
      state.filterOrders = action.payload;
    },
  }
);

export default userReducer;
