import "./Heading.scss";

export const Heading1 = ({ title, classNames }) => {
  return (
    <>
      {/* <p className={`heading_banner continue_div   ${classNames}`}>{title}</p> */}
      <div className={` continue_div ${classNames} flex-md-grow-1`}>
        <span className="heading_banner">{title}</span>
      </div>
    </>
  );
};

export const Heading2 = ({ title, classNames, onClick }) => {
  return (
    <>
      <p onClick={onClick} className={` Heading2 ${classNames} `}>
        {title}
      </p>
    </>
  );
};
