import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";
import { Img_url } from "../../constant";
import { GET } from "../../constant/RequestAuthService";
import { useNavigate } from "react-router-dom";
import { SetSession } from "../../constant/Session";
import { Stringify } from "../../constant/Util";
import { CategoryListLoader } from "../../components/skeletons/Loader";

const SubCategoryCurosel = ({ id, setSubCategory, subcategory }) => {
  useEffect(() => {
    handleCategory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleCategory();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategory = async () => {
    const response1 = await GET("subcategory", { id: id });
    if (response1.status === 200) {
      setSubCategory(response1.data);
    }
  };

  const navigate = useNavigate();

  const SubCategoryProduct = (data) => {
    SetSession("C_ID", Stringify(data.category_id));
    navigate(`/subCategories/${data.id}/${data.category_id}`);
  };

  // console.log(subcategory);
  return (
    <>
      {subcategory && typeof subcategory === "object" ? (
        <>
          <div className="mt-3 ">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay
              autoPlaySpeed={4000}
              centerMode={false}
              className=""
              containerclassName="container-with-dots"
              dotListclassName=""
              draggable
              focusOnSelect={false}
              infinite
              itemclassName=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 26000,
                    min: 1024,
                  },
                  items: 6,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                bigTablet: {
                  breakpoint: {
                    max: 1024,
                    min: 769,
                  },
                  items: 4,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 768,
                    min: 464,
                  },
                  items: 3,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderclassName=""
              slidesToSlide={2}
              swipeable
            >
              {subcategory?.length > 0 &&
                subcategory.map((data, ind) => (
                  <Grid
                    className=" pe-sm-4 pe-0 p_2 "
                    key={ind}
                    style={{ cursor: "pointer" }}
                    onClick={
                      () => SubCategoryProduct(data)
                      // () => navigate(`/subCategories/${data.id}`)
                    }
                  >
                    <Grid
                      sx={{ overflow: "hidden" }}
                      className="d-flex justify-content-between align-items-center flex-column border_icons_cat category_box"
                    >
                      <img
                        src={`${Img_url}${data.picture}`}
                        alt="img"
                        className="img-fluid"
                        style={{ height: "100px " }}
                      />
                      <p className="typ">{data.name}</p>
                    </Grid>
                  </Grid>
                ))}
            </Carousel>
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 ">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay
              autoPlaySpeed={4000}
              centerMode={false}
              className=""
              containerclassName="container-with-dots"
              dotListclassName=""
              draggable
              focusOnSelect={false}
              infinite
              itemclassName=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 26000,
                    min: 1024,
                  },
                  items: 5,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                bigTablet: {
                  breakpoint: {
                    max: 1024,
                    min: 769,
                  },
                  items: 4,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 768,
                    min: 464,
                  },
                  items: 3,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderclassName=""
              slidesToSlide={2}
              swipeable
            >
              {[...Array(10).keys()].map((data, i) => (
                <div key={i}>
                  <CategoryListLoader />
                </div>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default SubCategoryCurosel;
