import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import {
  FrontImages,
  MoreProductReload,
  RememberMeActivated,
  useSavedItems,
} from "./components/useCustomHooks/useFunctions";
import RootRoutes from "./routes/RootRoutes";

const App = () => {
  const isUserAlreadyExist = useSelector((state) => state.apiReducer.loading);
  const { SavedCartData } = useSavedItems();

  FrontImages();
  MoreProductReload();
  SavedCartData();
  RememberMeActivated();

  return (
    <>
      {isUserAlreadyExist === true ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              background: "#fff",
            }}
            open={isUserAlreadyExist === true}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        </>
      ) : (
        <RootRoutes />
      )}
    </>
  );
};

export default App;

// name company name mrp require
