import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../../../constant/RequestAuthService";
import { DECODE, FeatureProduct } from "../../../constant/Util";
import ViewAllButton from "../../../sharedComponents/viewallButton/ViewAllButton";
import ProductCarousel from "../productCarousel/ProductCarousel";

const TopOffers = () => {
  const dispatch = useDispatch();

  const Heading = useSelector((state) => state.apiReducer.ProductHeading);
  const handleHeadingText = async () => {
    const response1 = await GET("pages/features_heading");
    if (response1.status === 200) {
      dispatch({ type: "HeadingText", payload: response1.data });
    }
  };

  useEffect(() => {
    if (Heading === "") {
      handleHeadingText();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="container mt-3">
        <div className="row shadow_gr">
          <div className="col-sm-12">
            <div className="d-flex align-items-center justify-content-between">
              {Heading !== "" && (
                <div
                  className="heading_banner2"
                  dangerouslySetInnerHTML={{ __html: DECODE(Heading) }}
                ></div>
              )}

              <ViewAllButton featured />
            </div>

            <ProductCarousel data={FeatureProduct()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TopOffers);
