import { Grid, Skeleton } from "@mui/material";
import "./Loader.css";

export const SliderLoader = () => {
  return (
    <Skeleton animation="wave" variant="rounded" className="SliderLoader" />
  );
};

export const BannerLoader = () => {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      height={200}
      sx={{ width: "100%" }}
    />
  );
};

export const CategoryListLoader = () => {
  return (
    <div className="px-3">
      <Skeleton variant="rounded" height={200} />
    </div>
  );
};

export const ProductCardLoader = () => {
  return (
    <Grid className="d-flex justify-content-center align-items-center flex-column  top_offer_card  mx-3">
      <Grid className="w-100 px-3" sx={{ overflow: "hidden" }}>
        <Skeleton variant="rounded" height={150} />

        <Skeleton
          className="mt-2  fs_14 fw_500"
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
        <Skeleton
          className="mt-2  fs_15 fw_700 "
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
      </Grid>
    </Grid>
  );
};

export const CategoryCardLoader = () => {
  return (
    <Grid className="d-flex justify-content-center align-items-center flex-column  top_offer_card  ">
      <Grid className="w-100 " sx={{ overflow: "hidden" }}>
        <Skeleton variant="rounded" height={150} />

        <Skeleton
          className="mt-2  fs_14 fw_500"
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
        <Skeleton
          className="mt-2  fs_15 fw_700 "
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
      </Grid>
    </Grid>
  );
};
