import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  count: null,
  msg: null,
};

const subCategoriesReducer = createReducer(initialState, {});

export default subCategoriesReducer;
