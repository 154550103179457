import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Heading1, Heading2 } from "../../components/heading/Heading";

import { CategoryProducts } from "../../constant/Util";
import { OutlineButton } from "../../components/buttons/Button";
import {
  IsProductAdded,
  useCartFunctanilty,
} from "../../components/useCustomHooks/useFunctions";
import { POST, GET } from "../../constant/RequestAuthService";
import { useSelector, useDispatch } from "react-redux";
import { SetSession } from "../../constant/Session";
import { Stringify } from "../../constant/Util";
import { CategoryCardLoader } from "../../components/skeletons/Loader";

import ProductCard from "../../components/productCard/ProductCard";

const CategoryProduct = ({ fetchProduct, setFetchProduct }) => {
  const navigate = useNavigate();

  const getCategoryProduct = CategoryProducts();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState(null);
  const [loading, setLoading] = useState(false);
  const { HandlewishtList, AddToCart, Decrement, Increment, DeleteCart } =
    useCartFunctanilty();
  IsProductAdded();

  const categoryList = useSelector((state) => state.apiReducer);

  useEffect(() => {
    if (
      categoryList &&
      (typeof categoryList.categoryList === "object" ||
        categoryList.categoryList.length === 0)
    ) {
      handleCategory();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategory = async () => {
    setLoading(true);
    const response1 = await GET("category");
    if (response1.status === 200) {
      setLoading(false);
      dispatch({ type: "categoryList", payload: response1.data });
    }
    setLoading(false);
  };

  useEffect(() => {
    setCategory_id(
      pathname.slice(pathname.lastIndexOf("/") + 1, pathname.length)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (category_id !== null) {
      const categoryName = categoryList.categoryList.filter(
        (item) => item.id === parseInt(category_id)
      );
      setName(categoryName?.[0]?.name);
    }
  }, [category_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const ShowMoreProduct = async () => {
    setFetchProduct(true);
    const sendData = {
      input: null,
      sub_category_id: null,
      name,
    };

    const response = await POST("products");
    if (response.status === 200) {
      dispatch({
        type: "getSearchProduct",
        payload: response.data,
      });
      SetSession("sendData", Stringify(sendData));
      setFetchProduct(false);
      navigate("/product");
    }
    setFetchProduct(false);
    // console.log(category_id);
  };

  return (
    <>
      {loading ? (
        <div className="container mt-3">
          <div className="row">
            {[...Array(8).keys()].map((data, i) => (
              <div key={i} className=" col-md-4 col-xl-3 col-xxl-2  mb-4">
                <CategoryCardLoader />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="d-flex align-items-center justify-content-end  mt-2 ">
              {getCategoryProduct && typeof getCategoryProduct === "object" ? (
                <>
                  {getCategoryProduct.length === 0 ? (
                    <>
                      <OutlineButton
                        title="View All"
                        onClick={() => navigate("/products")}
                      />
                    </>
                  ) : (
                    <>
                      <Heading1
                        title="Product Related Category"
                        classNames="mt-4"
                      />
                      <OutlineButton
                        title="View more"
                        onClick={ShowMoreProduct}
                        // () => navigate("/product")
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <CircularProgress />
                </>
              )}
            </div>
            <div className="row mt-4">
              {getCategoryProduct && typeof getCategoryProduct === "object" ? (
                <>
                  {getCategoryProduct.length === 0 ? (
                    <>
                      <Heading2 title="No Product Related to this Category" />
                    </>
                  ) : (
                    <>
                      {getCategoryProduct.map((data, id) => (
                        <div
                          key={id}
                          className="col-md-4 col-xl-3 col-xxl-2 py-2 px-0 p-md-3"
                        >
                          <ProductCard
                            data={data}
                            HandlewishtList={HandlewishtList}
                            AddToCart={AddToCart}
                            Increment={Increment}
                            Decrement={Decrement}
                            DeleteCart={DeleteCart}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "900px" }}
                >
                  {/* <CircularProgress color="secondary" /> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CategoryProduct;
