import * as React from "react";

import UploadFile from "@mui/icons-material/UploadFile";
import { PrimaryButton } from "../../components/buttons/Button";

import { Heading1, Heading2 } from "../../components/heading/Heading";
import { memo } from "react";
import { Button, IconButton } from "@mui/material";
import Del from "../../assets/images/Del.png";
import StoredVariables, {
  GetSession,
  SetSession,
} from "../../constant/Session";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GET, POST } from "../../constant/RequestAuthService";
import Validator from "validatorjs";
import { DECODE } from "../../constant/Util";
import { useDispatch, useSelector } from "react-redux";

const FileUploadPage = () => {
  const [file, setFile] = React.useState();
  const [images, setImages] = React.useState([]);
  const [inputs, setInputs] = React.useState({
    patient: "",
    doctor: "",
    instructions: "",
  });

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files;
    setImages([...files]);
  }

  const handleCancel = () => {
    setFile(null);
    setImages(null);
  };
  const isLogin = GetSession(StoredVariables.username);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLogin) {
      navigate("/login");
      toast.error("Please login first");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadData = new FormData();
    uploadData.append("name", inputs.patient);
    uploadData.append("doctor_name", inputs.doctor);
    images.forEach((image) => {
      uploadData.append("photo[]", image);
    });

    const requestData = {
      name: inputs.patient,
      doctor_name: inputs.doctor,
      photo: images,
    };

    const validation = new Validator(requestData, {
      photo: "required",
    });
    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
    } else {
      if (inputs.instructions !== "") {
        uploadData.append("instructions", inputs.instructions);
      }
      const response = await POST("prescription/upload", uploadData, true);
      // console.log(response);
      toast.success(response.message);
      dispatch({ type: "SavedRecords", payload: response.data });
      SetSession("activetabsMenu", 4);
      navigate("/loginDashboard");
    }
  };

  const dispatch = useDispatch();

  const instruction = useSelector((state) => state.apiReducer.prescription);
  // console.log(typeof instruction);

  const handleData = async () => {
    const response1 = await GET("pages", { id: "priscription_instruction" });
    if (response1.status === 200) {
      let data = DECODE(response1.data);
      // console.log(data);

      await dispatch({
        type: "getPrescription_data",
        payload: data,
      });
    }
  };

  React.useEffect(() => {
    instruction && !instruction.length && handleData();
  }, [instruction]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(file);
  return (
    <>
      <div className=" pt-5 pt-md-0 d-flex flex-column ">
        <div className="mt-3  mb-3 container flex-grow-1">
          <div className="row">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row gx-5">
                  <div className="col-sm-6 ps-0">
                    <Heading1 title=" Choose your option" />
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div className=" d-flex align-items-center justify-content-center flex-column top_offer_card  mt-3 br_8 py-4">
                          {!images ? (
                            <div className=" d-flex align-items-center justify-content-center flex-column">
                              <UploadFile fontSize="large" />
                              <Heading2
                                title="Upload Prescription *"
                                classNames="text-center py-3"
                              />
                              <Button
                                component="label"
                                className={`outline_btn`}
                              >
                                Upload
                                <input
                                  onChange={handleChange}
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                  name="files"
                                />
                              </Button>{" "}
                            </div>
                          ) : (
                            <>
                              <div className="d-flex justify-content-between w-100 px-3 pb-3 align-items-center">
                                {/* <SecondaryButton
                              title="Cancel"
                              onClick={handleCancel}
                            /> */}
                                <IconButton onClick={handleCancel}>
                                  <img src={Del} alt="delete" width="30px" />
                                </IconButton>
                              </div>
                              <img
                                src={file}
                                alt="file"
                                className="img-fluid"
                              />
                            </>
                          )}
                        </div>
                        <input
                          name="patient"
                          type="text"
                          value={inputs.patient}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Patient Name (optional)"
                          className="login_field w-100 mt-4"
                        />
                        <input
                          name="doctor"
                          type="text"
                          value={inputs.doctor}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Doctor's Name (optional)"
                          className="login_field w-100 mt-4"
                        />
                        {/* 
                        <textarea
                          value={inputs.instructions}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              [e.target.name]: e.target.value,
                            })
                          }
                          name="instructions"
                          id=""
                          rows="5"
                          placeholder="Dear Customer, you can instruct our sales person by providing:&#10;-> Complaints.&#10;-> You want more quantity of the product if it reaches its maximum quantity.&#10;-> If you want medicines for particular days or months."
                          className="tb mt-4 w-100 p-2 login_field"
                        ></textarea> */}
                        <PrimaryButton title="Submit" classNames="mt-4 mb-4" />
                      </div>
                    </form>
                  </div>
                  <div className="col-sm-6 pe-0">
                    {" "}
                    <Heading2
                      title=" Your Uploads should Contain"
                      classNames="mt-1 mb-2"
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: instruction }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(FileUploadPage);
