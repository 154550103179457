import React, { memo, useLayoutEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
// import { IconButton } from "@mui/material";

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { GET } from "../../constant/RequestAuthService";
import { Img_url } from "../../constant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import health_r from "../../assets/images/health_r.png";

import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
// import profileuser from "../../../src/assets/images/profileuser.png";
// import order from "../../../src/assets/images/order.png";
// import wishlist from "../../../src/assets/images/wishlist.png";
// import req from "../../../src/assets/images/req.png";

// import logout from "../../../src/assets/images/logout.png";
import StoredVariables, {
  GetSession,
  RemoveLocalSession,
  SetSession,
} from "../../constant/Session";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Reload } from "../../constant/Util";

const dropdownData = [
  {
    title: "My Profile",
    icon: AccountCircleIcon,
    path: "/loginDashboard",
    index: 0,
  },
  {
    title: "My Orders",
    icon: LocalMallIcon,
    path: "/order",
    index: 2,
  },
  {
    title: "My WishList",
    icon: FavoriteIcon,
    path: "/wishlist",
    index: 3,
  },

  {
    title: " Records",
    icon: LocalHospitalIcon,
    path: "/healthRecords",
    index: 4,
  },
  {
    title: " Requests",
    icon: CrisisAlertIcon,
    path: "/productrequest",
    index: 5,
  },
  {
    title: "Logout",
    icon: ExitToAppIcon,
  },
];

const UserDropdown = ({ setIsuser, isUser }) => {
  const [userDrop, setUserDrop] = useState(false);

  const getProfile = async () => {
    const profile = await GET("profile");

    if (profile.status === 200) {
    }
  };

  const isLogin = GetSession(StoredVariables.username);

  useLayoutEffect(() => {
    if (isLogin && !userProfile) {
      getProfile();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const userProfile = useSelector(
    (state) => state.userReducer.userProfile.data
  );

  const navigate = useNavigate();

  const handleUserlogout = async () => {
    try {
      setUserDrop(true);
      await GET("logout");
      sessionStorage.clear();
      RemoveLocalSession(StoredVariables.email);
      RemoveLocalSession(StoredVariables.RememberMe);
      RemoveLocalSession("userProfile");
      RemoveLocalSession(StoredVariables.password);
      setIsuser(!isUser);
      localStorage.clear();
      toast.success("Logout Successfully...");
      SetSession(StoredVariables.reload, true);
      Reload();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  // const dropdownData = [
  //   {
  //     title: "My Profile",
  //     icon: AccountCircleIcon,
  //     path: "/loginDashboard",
  //     index: 0,
  //   },
  //   {
  //     title: "My Orders",
  //     icon: order,
  //     path: "/order",
  //     index: 2,
  //   },
  //   {
  //     title: "My WishList",
  //     icon: wishlist,
  //     path: "/wishlist",
  //     index: 3,
  //   },

  //   {
  //     title: " Records",
  //     icon: health_r,
  //     path: "/healthRecords",
  //     index: 4,
  //   },
  //   {
  //     title: " Requests",
  //     icon: req,
  //     path: "/productrequest",
  //     index: 5,
  //   },
  //   {
  //     title: "Logout",
  //     icon: logout,
  //   },
  // ];

  const navigateActive = (data) => {
    navigate("/loginDashboard");
    SetSession("activetabsMenu", data.index);
    // console.log(i);
  };

  // console.log(userProfile.first_name.charAt(0));
  return (
    <>
      <div
        style={{ textDecoration: "none", color: "inherit" }}
        className="px-2 fs_15 fw_500 relative "
      >
        {userProfile && userProfile.picture ? (
          <StyledBadge
            onClick={() => setUserDrop(!userDrop)}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            // sx={{ zIndex: 99999999 }}
          >
            <Avatar
              alt="Remy Sharp"
              src={`${Img_url}${userProfile.picture}`}
              sx={{ cursor: "pointer" }}
            >
              {userProfile?.first_name?.charAt(0).toUpperCase()}
            </Avatar>
          </StyledBadge>
        ) : (
          <StyledBadge
            onClick={() => setUserDrop(!userDrop)}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            sx={{ zIndex: 99999999 }}
          >
            <Avatar sx={{ cursor: "pointer" }}>
              {userProfile?.first_name?.charAt(0).toUpperCase()}
            </Avatar>
          </StyledBadge>
        )}
        {/* {userDrop && ( */}
        <OutsideClickHandler onOutsideClick={() => setUserDrop(false)}>
          <AnimatePresence>
            {userDrop && (
              <motion.ul
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                // transition={{ type: "spring", duration: 1, bounce: 0.3 }}
                className={" list-unstyled login_reg_dropdown2_active"}
              >
                {dropdownData.map((data, i) => (
                  <li key={i} className="my-2">
                    <div
                      className="dropdown-item fw_700"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        data.title === "Logout"
                          ? handleUserlogout()
                          : navigateActive(data, i)
                      }
                    >
                      <data.icon
                        className="me-1"
                        sx={{
                          color: `${
                            i === 2 ? ` var(--secondary)` : ` var(--primary)`
                          }`,
                        }}
                      />
                      &nbsp;{data.title}
                    </div>
                  </li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </OutsideClickHandler>
        {/* )} */}
      </div>
    </>
  );
};

export default memo(UserDropdown);
