import React, { useEffect, useState } from "react";
import login_vector from "../../../assets/images/login_vector.jpg";
// import { Link } from "react-router-dom";
import {
  LoaderButton,
  SecondaryButton,
} from "../../../components/buttons/Button";
import { toast } from "react-toastify";
// import { URL } from "../../../redux/api/Api";
import * as Validator from "validatorjs";
import { GET, POST } from "../../../constant/RequestAuthService";
import StoredVariables, {
  GetSession,
  RemoveLocalSession,
} from "../../../constant/Session";

import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [Login, setLogin] = useState({
    oldpassword: "",
    new_password: "",
    c_password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isLogin = GetSession(StoredVariables.username);

  useEffect(() => {
    !isLogin && navigate("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    e.preventDefault();
    setLogin({ ...Login, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    setLoading(true);

    const checkdata = {
      oldpassword: "required",
      new_password: "required|min:8",
      c_password: "required|same:new_password",
    };
    const validation = new Validator(Login, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);

      // toast.error("fail");
      // await Validation(validation.errors.errors);
    } else {
      const response = await POST("changepassword", Login);
      // console.log(response.status);
      if (response.status === 200) {
        setLoading(true);
        toast.success(response.message);
        handleUserlogout();
        setLoading(false);
        // window.location.reload();
      } else {
        toast.error(response.message);
        setLoading(true);
        setLoading(false);
      }
    }
  };

  const handleUserlogout = async () => {
    try {
      setLoading(true);

      const response = await GET("logout");

      // console.log(response);
      if (response.status === 200) {
        setLoading(false);
        sessionStorage.clear();
        RemoveLocalSession(StoredVariables.email);
        RemoveLocalSession(StoredVariables.RememberMe);
        RemoveLocalSession("userProfile");
        RemoveLocalSession(StoredVariables.password);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoading(false);
        toast.error("please try again...");
      }
    } catch (err) {
      setLoading(true);
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <div style={{ flexGrow: 1 }} className="container p-4">
        <div className="row">
          <div className="col-sm-6">
            <img src={login_vector} alt="log" className="img-fluid" />
          </div>
          <div className="col-sm-6 mt-5 ">
            <input
              name="oldpassword"
              value={Login.oldpassword}
              onChange={handleChange}
              type="password"
              placeholder=" Old Password"
              className="login_field mt-4"
            />
            <input
              name="new_password"
              value={Login.new_password}
              onChange={handleChange}
              type="password"
              placeholder="New Password"
              className="login_field mt-4"
            />
            <input
              name="c_password"
              value={Login.c_password}
              onChange={handleChange}
              type="password"
              placeholder="Confirm Password"
              className="login_field mt-4"
            />
            <div className="mt-4">
              {loading ? (
                <LoaderButton classNames="login_btns" title="Sign In" />
              ) : (
                <SecondaryButton
                  onClick={handleSubmit}
                  classNames="login_btns"
                  title="Sign In"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
