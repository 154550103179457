import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GET, POST } from "../../constant/RequestAuthService";
import StoredVariables, {
  GetLocalSession,
  GetSession,
  SetLocalSession,
  SetSession,
} from "../../constant/Session";
import {
  AllProduct,
  CartItemSend,
  CopyRightText,
  DECODE,
  ENCODE,
  FeatureProduct,
  FeatureProducts,
  HomeProduct,
  IsOnline,
  Json,
  NewAllProduct,
  SavedWishList,
  Stringify,
  UserLogin,
} from "../../constant/Util";
import { UpdateCartApi } from "../../pages/home/topOffers/AddCartFunction";

export const IsRememberMeActivated = async () => {
  const dispatch = useDispatch();

  const rememberme = GetLocalSession(StoredVariables.RememberMe);
  const savedEmail = GetLocalSession(StoredVariables.email);
  const savedPassword = GetLocalSession(StoredVariables.password);

  const isRememberme =
    rememberme && Json(DECODE(GetLocalSession(StoredVariables.RememberMe)));
  const email =
    savedEmail && Json(DECODE(GetLocalSession(StoredVariables.email)));

  const password =
    savedPassword && Json(DECODE(GetLocalSession(StoredVariables.password)));

  const isAlreadyLogin = GetSession(StoredVariables.logindata);

  if (isRememberme && email && password && !isAlreadyLogin) {
    dispatch({ type: "LoadingStart" });

    const Login = {
      email,
      password,
    };

    const response = await POST("login", Login);
    if (response.status === 200) {
      dispatch({ type: "LoadingStop" });
      SetSession(StoredVariables.logindata, ENCODE(Stringify(response.data)));
      SetSession(StoredVariables.email, Login.email);
      SetSession(StoredVariables.username, response.data.name);
      SetSession(StoredVariables.role, response.data.Role.role);
      SetLocalSession(StoredVariables.email, ENCODE(Stringify(Login.email)));
      SetLocalSession(
        StoredVariables.password,
        ENCODE(Stringify(Login.password))
      );
    } else {
      dispatch({ type: "LoadingStop" });
      toast.error(response.message);
    }
  } else {
    return;
  }
};

export const IsProductAdded = () => {
  const getValues = useSelector((state) => state.cartsReducer.getAllvalues);
  const dispatch = useDispatch();
  useEffect(() => {
    const prAdd = async () => {
      await dispatch({ type: "IsProductAdded" });
    };
    prAdd();
  }, [getValues]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const ProductReload = () => {
  const allProduct = AllProduct();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (allProduct && allProduct.length === 0) {
  //     getallProduct();
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //   const getallProduct = async () => {
  //     const response1 = await POST("home", { limit: 20 });
  //     if (response1.status === 200) {
  //       // console.log(response1.data);
  //       dispatch({
  //         type: "getAllProduct",
  //         payload: response1.data,
  //       });
  //     }
  //   };
};

// export const HomeProductReload = () => {
//   const allProduct = HomeProduct();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (allProduct && allProduct.length === 0) {
//       getallProduct();
//     }
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   const getallProduct = async () => {
//     const response1 = await GET("home");
//     if (response1.status === 200) {
//       dispatch({
//         type: "getHomeProduct",
//         payload: response1.data,
//       });
//     }
//   };
// };

export const FeatureProductReload = () => {
  const featureProduct = FeatureProduct();
  const dispatch = useDispatch();

  useEffect(() => {
    if (featureProduct && featureProduct.length === 0) {
      getfeaturedProduct();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getfeaturedProduct = async () => {
    const response1 = await GET("fetured");
    dispatch({ type: "PLoadingStart" });
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "PLoadingStop" });
      dispatch({
        type: "getFeaturedProduct",
        payload: response1.data,
      });
    }
    dispatch({ type: "PLoadingStop" });
  };
};

export const MoreFeatureProductReload = () => {
  const featureProduct = FeatureProducts();
  const dispatch = useDispatch();

  useEffect(() => {
    if (featureProduct && featureProduct.length === 0) {
      getfeaturedProduct();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getfeaturedProduct = async () => {
    const response1 = await POST("products", { is_fetured: 1 });
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({
        // type: "getHomeProduct",
        type: "getMoreFeaturedProduct",
        payload: response1.data,
      });
    }
  };
};

export const MoreProductReload = () => {
  const dispatch = useDispatch();
  const newallProduct = NewAllProduct();
  useEffect(() => {
    if (newallProduct && newallProduct.length === 0) {
      getallProducts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getallProducts = async () => {
    dispatch({ type: "LoadingStartProduct" });
    const response1 = await POST("products");
    if (response1.status === 200) {
      dispatch({ type: "LoadingStopProduct" });

      dispatch({
        type: "getMoreProduct",
        payload: response1.data,
      });
    }
    dispatch({ type: "LoadingStopProduct" });
  };
};

export const FeaturedProductREload = () => {
  const dispatch = useDispatch();
  const newallProduct = NewAllProduct();
  useEffect(() => {
    if (newallProduct && newallProduct.length === 0) {
      getallProducts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getallProducts = async () => {
    dispatch({ type: "LoadingStartProduct" });
    const response1 = await POST("products", { is_featured: 1 });
    if (response1.status === 200) {
      dispatch({ type: "LoadingStopProduct" });

      dispatch({
        type: "getFeaturedProducts",
        payload: response1.data,
      });
    }
    dispatch({ type: "LoadingStopProduct" });
  };
};

export const FrontImages = () => {
  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);
  const dispatch = useDispatch();
  const GetFrontImages = async () => {
    const response = await GET("images");
    if (response.status === 200) {
      // console.log(response.data);
      localStorage.setItem("frontImages", JSON.stringify(response.data));
      dispatch({ type: "getFrontImage", payload: response.data });
      // Reload();
    }
  };

  useLayoutEffect(() => {
    if (!isFrontImages || isFrontImages.length === 0) {
      GetFrontImages();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const Banners = () => {
  const getBanner = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (getBanner.banner && getBanner.banner.length === 0) {
      handleBanner();
    }
    handleBanner();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBanner = async () => {
    const response1 = await GET("banners");
    if (response1.status === 200) {
      console.log("run");
      dispatch({ type: "getBanner", payload: response1.data });
    }
  };
};

export const CategoryLists = () => {
  const categoryList = useSelector((state) => state.apiReducer.categoryList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (categoryList.length === 0) {
      handleCategory();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategory = async () => {
    const response1 = await GET("category");
    if (response1.status === 200) {
      dispatch({ type: "categoryList", payload: response1.data });
    }
  };
};

export const useCartFunctanilty = () => {
  const [fetch, setFetch] = useState("");
  const [removed, setRemoved] = useState("");
  const navigate = useNavigate();
  const isUserLogin = UserLogin();
  const dispatch = useDispatch();
  const savedWishList = SavedWishList();

  const isLogin = GetSession(StoredVariables.username);
  const cartItemSend = CartItemSend();

  const AddToCart = async (data) => {
    // console.log(data);
    // if (isUserLogin && IsOnline) {
    await dispatch({
      type: "AddItems",
      payload: data,
    });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // } else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };

  const Increment = async (id) => {
    dispatch({ type: "UpdateData", payload: id });
    await dispatch({ type: "IsProductAdded" });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const Decrement = async (id) => {
    dispatch({ type: "DegradeData", payload: id });
    await dispatch({ type: "IsProductAdded" });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
  };

  const DeleteCart = async (id) => {
    // if (isUserLogin && IsOnline) {
    await dispatch({ type: "DeleteItems", payload: id });
    setFetch("fetch");
    setTimeout(() => {
      setFetch("");
    }, 300);
    // } else {
    //   toast.error("Please login first");
    //   navigate("/login");
    // }
  };
  const HandlewishtList = async (data) => {
    if (!isLogin) {
      navigate("/login");
      toast.error("Please login first");
    } else {
      const sendData = {
        product_id: data,
      };

      // console.log(data);
      dispatch({ type: "wishLoadtrue", payload: data });
      const response = await POST("product/faviorate", sendData);

      if (response.status === 200) {
        toast.success(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
        dispatch({ type: "wishLoadfalse" });
      }
      if (response.status === 203) {
        toast.error(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
      }
      dispatch({ type: "wishLoadfalse" });
    }
    dispatch({ type: "wishLoadfalse" });
  };

  const getWishList = async () => {
    const response1 = await GET("product/faviorate");
    if (response1.status === 200) {
      dispatch({ type: "SavedWishList", payload: response1.data });
    }
  };

  useEffect(() => {
    if (isLogin && savedWishList.length === 0) {
      getWishList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    removed === "remove" && getWishList();
  }, [removed]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch === "fetch" && UpdateCartApi(cartItemSend);
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  return { AddToCart, Increment, Decrement, DeleteCart, HandlewishtList };
};

export const useSavedItems = () => {
  const SavedCartData = () => {
    const [fetch, setFetch] = useState("");
    const [items, setItems] = useState([]);
    const [totalCart, setTotalCart] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [total_bill_price, setTotal_bill_price] = useState(0);
    const dispatch = useDispatch();

    const getCart = async () => {
      if (GetSession(StoredVariables.username)) {
        const response1 = await GET("cart");
        if (response1.status === 200) {
          // console.log(response1.data);
          setItems(Json(response1.data && response1.data.items));
          setTotalCart(response1.data && parseInt(response1.data.total_cart));
          setTotalPrice(response1.data && parseInt(response1.data.total_price));
          setTotal_bill_price(
            response1.data && parseInt(response1.data.total_bill_price)
          );
          setFetch("savedData");
          setTimeout(() => {
            setFetch("");
          }, 300);
        }
      }
    };

    useEffect(() => {
      getCart();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      let savedData = {
        items: items === false || items === null ? [] : items,
        totalCart,
        totalPrice,
        total_bill_price,
      };
      // console.log(savedData);
      fetch === "savedData" &&
        dispatch({ type: "SavedData", payload: savedData });
    }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps
  };
  return { SavedCartData };
};

export const CopyRightTexts = () => {
  const dispatch = useDispatch();
  const Text = CopyRightText();

  useEffect(() => {
    if (!Text.length) {
      handleData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleData = async () => {
    const response1 = await GET("pages", { id: "copyright" });
    if (response1.status === 200) {
      dispatch({ type: "CopyRightText", payload: DECODE(response1.data) });
    }
  };
};

export const RemoveSendData = () => {
  useLayoutEffect(() => {
    sessionStorage.removeItem("sendData");
  }, []);
};

export const RememberMeActivated = () => {
  const dispatch = useDispatch();
  const rememberme = GetLocalSession(StoredVariables.RememberMe);
  const savedEmail = GetLocalSession(StoredVariables.email);
  const savedPassword = GetLocalSession(StoredVariables.password);

  const isRememberme =
    rememberme && Json(DECODE(GetLocalSession(StoredVariables.RememberMe)));
  const email =
    savedEmail && Json(DECODE(GetLocalSession(StoredVariables.email)));

  const password =
    savedPassword && Json(DECODE(GetLocalSession(StoredVariables.password)));

  const isAlreadyLogin = GetSession(StoredVariables.logindata);

  useEffect(() => {
    const isUserExist = async () => {
      if (isRememberme && email && password && !isAlreadyLogin) {
        dispatch({ type: "LoadingStart" });

        const Login = {
          email,
          password,
        };

        const response = await POST("login", Login);
        if (response.status === 200) {
          dispatch({ type: "LoadingStop" });
          SetSession(
            StoredVariables.logindata,
            ENCODE(Stringify(response.data))
          );
          SetSession(StoredVariables.email, Login.email);
          SetSession(StoredVariables.username, response.data.name);
          SetSession(StoredVariables.role, response.data.Role.role);
          SetLocalSession(
            StoredVariables.email,
            ENCODE(Stringify(Login.email))
          );
          SetLocalSession(
            StoredVariables.password,
            ENCODE(Stringify(Login.password))
          );
        } else {
          dispatch({ type: "LoadingStop" });
          toast.error(response.message);
        }
      } else {
        return;
      }
    };
    isUserExist();
  }, [isRememberme, password, email, isAlreadyLogin]); // eslint-disable-line react-hooks/exhaustive-deps
};

// remove dot from slider -d
// header and cat dropdown SouthAmerica -d
// login register dropdown -d
// cart section button -d
// padding in container -d
// copyright 2023 both in mobitplus -d
// why choose link in green color -d
// hover effect in categories Carousel
// height remove and padding from categories carousel
// margin remove from
