import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SubCategoryCurosel from "./SubCategoryCurosel";
import { GET } from "../../constant/RequestAuthService";
import CategoryProduct from "./CategoryProduct";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "@mui/material";
import { Heading1, Heading2 } from "../../components/heading/Heading";
import { ProductCardLoader } from "../../components/skeletons/Loader";

const SubCategory = () => {
  const [subcategory, setSubCategory] = useState(null);
  const [categoryProduct, setCategoryProduct] = useState(null);
  const [activeCat, setActiveCat] = useState("");
  const [fetchProduct, setFetchProduct] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    getCategoryProduct();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryProduct = async () => {
    const response1 = await GET("product/category", { id: id });
    console.log(response1);
    if (response1.status === 200) {
      if (response1.data && response1.data) {
        dispatch({ type: "CategoryProduct", payload: response1.data });
      } else {
        dispatch({ type: "NoCategoryProduct" });
      }
    }
  };

  useEffect(() => {
    getCategoryProduct();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryList = useSelector((state) => state.apiReducer.categoryList);

  useEffect(() => {
    if (categoryList && categoryList.length !== 0) {
      const filtArr = categoryList.find((item) => item.id === parseInt(id));

      setActiveCat(filtArr.name);
    }
  }, [id, categoryList]);

  // console.log(activeCat);

  return (
    <>
      {fetchProduct === true ? (
        <div className="container mt-3 pt-4">
          <div className="row gx-4 px-3 px-sm-0">
            {[...Array(4).keys()].map((data, i) => (
              <div key={i} className=" col-md-4 col-xl-3 col-xxl-2 p-0">
                <ProductCardLoader />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className=" pt-md-0 d-flex flex-column"
          style={{ minHeight: "100vh" }}
        >
          <div className="container">
            <div className="mt-3 bread_cum">
              <Breadcrumbs aria-label="breadcrumb">
                <Heading2 title={activeCat} classNames="primary" />
                {/* <>{activeCat}</> */}
              </Breadcrumbs>
            </div>
            <div className="row ">
              <Heading1 title="SubCategory List" classNames="mt-4" />
              <SubCategoryCurosel
                id={id}
                subcategory={subcategory}
                setSubCategory={setSubCategory}
              />
            </div>{" "}
            <CategoryProduct
              fetchProduct={fetchProduct}
              setFetchProduct={setFetchProduct}
              categoryProduct={categoryProduct}
              setCategoryProduct={setCategoryProduct}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SubCategory;
