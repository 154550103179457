import React, { memo, useEffect } from "react";
// import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import Carts from "./Carts";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import UserDropdown from "./UserDropdown";
import StoredVariables, {
  GetSession,
  SetSession,
} from "../../constant/Session";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  OutlineButton,
  PrimaryButton,
  SecondaryButton,
} from "../buttons/Button";
import AnnouncementBar from "../announcementBar/AnnouncementBar";
import { Grid, Typography } from "@mui/material";
import { CartItemSend, SearchProduct, Stringify } from "../../constant/Util";
import { useCartFunctanilty } from "../useCustomHooks/useFunctions";
import Del from "../../assets/images/Del.png";
import { Img_url } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { POST } from "../../constant/RequestAuthService";
import Notification from "./Notification";
import { Heading2 } from "../heading/Heading";
import { toast } from "react-toastify";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

const Responsiveheader = ({ drower, setDrower }) => {
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const allProduct = SearchProduct();
  const [userData, setUserData] = useState("");
  const [isUser, setIsuser] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const { AddToCart, Increment, Decrement, DeleteCart } = useCartFunctanilty();
  const cartItemSend = CartItemSend();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.cartsReducer.loading);
  const [showList, setShowList] = useState(false);
  const [catDropSmall, setCatDropSmall] = useState(false);
  const [name, setName] = useState(" All Categories");
  const [category_id, setCategory_id] = useState(null);
  const [getData, setGetData] = useState(false);
  const [sendData, setSendData] = useState();
  const sub_category_id = null;
  const { pathname } = useLocation();

  const userProfile = useSelector(
    (state) => state.userReducer.userProfile.data
  );
  // console.log("hy");

  useEffect(() => {
    setUserData(userProfile?.username);
  }, [userProfile]);

  useEffect(() => {
    setUserData(GetSession(StoredVariables.username));
  }, [isUser]);

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("run");
    if (search === "") {
      return;
    } else {
      dispatch({ type: "loadingtrue" });

      const response = await POST("products", sendData);

      if (response.status === 200) {
        dispatch({ type: "loadingfalse" });

        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        setShowList(true);
        setGetData(!getData);
      }

      dispatch({ type: "loadingfalse" });
      // console.log(category_id, input);
    }
  };

  const handleSearchi = async () => {
    if (search === "") {
      return;
    } else {
      dispatch({ type: "loadingtrue" });

      const response = await POST("products", sendData);

      if (response.status === 200) {
        dispatch({ type: "loadingfalse" });

        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        setShowList(true);
        setGetData(!getData);
      }

      dispatch({ type: "loadingfalse" });
    }
  };

  useEffect(() => {
    SetSession("sendData", Stringify({ ...sendData, name }));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getData = setTimeout(() => {
      if (search.length > 2) {
        handleSearchi();
      }
      if (search.length === 0) {
        setShowList(false);
        dispatch({ type: "emptySearchProduct" });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const OutSideClick = () => {
    setShowSearch(false);
    setSearch("");
  };

  const handelCatDrop = () => {
    setCatDropSmall(!catDropSmall);
    // console.log(catDropSmall);
  };

  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);
  const categoryList = useSelector((state) => state.apiReducer);

  const display = (data) => {
    setName(data.name);
    setCategory_id(data.id);
    setCatDropSmall(false);
  };

  useEffect(() => {
    setSendData({ category_id, search, sub_category_id });
  }, [category_id, search, sub_category_id]);

  const isLogin = GetSession(StoredVariables.username);

  const routeToMoreDetails = () => {
    if (isLogin) {
      navigate("/moreDetails");
    } else {
      navigate("/login");
      toast.error("Please login First");
    }
  };

  const displayall = () => {
    setName("All Categories");
    setCategory_id(null);
    setCatDropSmall(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={OutSideClick}>
        <div>
          <div
            style={{
              display: "block",
              height: "40px",
              background: "#fff",
            }}
          >
            <div className="responsiv_menu">
              <div className="d-flex align-item-center">
                <div
                  style={{ marginTop: pathname === "/product" && "-7px " }}
                  onClick={() => setDrower(!drower)}
                  className={drower ? "menu_top" : "menu_btn"}
                >
                  <div
                    className={drower ? "top_bar_roted" : "top_toggle"}
                  ></div>
                  <div
                    className={drower ? "top_bar_roted90" : "middle_toggle"}
                  ></div>
                  <div
                    className={drower ? "top_bar_roted45" : "bottom_toggle"}
                  ></div>
                </div>

                <div className="ms-2">
                  {pathname === "/product" ? (
                    <IconButton>
                      {/* <SearchIcon sx={{ color: "#fff" }} /> */}
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setShowSearch(!showSearch)}>
                      <SearchIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  )}
                </div>
              </div>
              <div onClick={() => navigate("/")} className="me-auto mx-auto ">
                {isFrontImages && isFrontImages.length !== 0 ? (
                  <img
                    src={
                      isFrontImages &&
                      `${Img_url}${isFrontImages[1].credencial_content}`
                    }
                    alt="logo"
                    className="img-fluid"
                    width="70px"
                  />
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </div>
              <div className="d-flex align-item-center">
                <div>
                  {userData !== null && userData !== "" ? (
                    <div className="mt-1">
                      <UserDropdown
                        userData={userData}
                        isUser={isUser}
                        setIsuser={setIsuser}
                      />
                    </div>
                  ) : (
                    <ClickAwayListener onClickAway={() => setShowDrop(false)}>
                      <div
                        className="dropdown_menu mt-1"
                        onClick={() => setShowDrop(true)}
                      >
                        <IconButton className="hover_it_drop hover_icon">
                          {/* {userData !== null && userData !== "" && `${userData}`} */}
                          <PersonIcon className="hover_icon" />
                        </IconButton>
                        <ul
                          className={` ${
                            showDrop && "show_login_reg_dropdown"
                          } login_reg_dropdown d-flex  align-items-center `}
                        >
                          <div className=" d-flex  align-items-center ">
                            <Link
                              style={{
                                textDecoration: "none",
                              }}
                              to="/login"
                            >
                              <div>
                                <SecondaryButton
                                  title="login"
                                  classNames="me-2 drop_btn"
                                />
                              </div>
                            </Link>
                            <Link
                              style={{
                                textDecoration: "none",
                              }}
                              to="/register"
                            >
                              <SecondaryButton title="Register" />
                            </Link>
                          </div>
                        </ul>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
                <Carts />
                <div className="me-2"> {userData && <Notification />}</div>
              </div>
            </div>
          </div>
          <div className={showSearch ? "search_bar" : "search_bar_hide"}>
            <div className="cat_search">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  onClick={handelCatDrop}
                  className="flex-grow-1 search_input_field2 me-2"
                  style={{
                    padding: "7px 20px",
                    border: " 2px solid var(--primary)",
                  }}
                >
                  {name}
                </div>

                <IconButton className="p-0" onClick={OutSideClick}>
                  <CloseIcon className="fs-5" />
                </IconButton>
              </div>
              <div className={catDropSmall ? "cat-drop" : "d-none"}>
                <ul className="cat-drop-list">
                  <li onClick={() => displayall()}>All categories</li>
                  {categoryList && categoryList.categoryList.length > 0
                    ? categoryList.categoryList.map((data, i) => (
                        <li key={i} onClick={() => display(data)}>
                          <img src={`${Img_url}${data.picture}`} alt="" />
                          <p> {data.name}</p>
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="search_relat">
              <form onSubmit={(e) => handleSearch(e)}>
                <input
                  placeholder="Search a product"
                  className="search_bar_reas"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IconButton type="submit" className="serch_abslut">
                  <SearchIcon />
                </IconButton>
              </form>
            </div>
            {loading ? (
              <>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: "300px" }}
                >
                  <CircularProgress color="secondary" />
                </div>
              </>
            ) : (
              <>
                {allProduct ? (
                  <>
                    {showList && (
                      <>
                        {allProduct.length !== 0 && (
                          <div className="d-flex align-items-center justify-content-end my-3">
                            <OutlineButton
                              title="View more"
                              onClick={() => navigate("/product")}
                            />
                          </div>
                        )}
                        <div className="search_list">
                          <ul>
                            {allProduct?.length !== 0 ? (
                              allProduct.map((data, i) => (
                                <li key={i}>
                                  <div
                                    onClick={() =>
                                      navigate(`/productDetails/${data.id}`)
                                    }
                                    className="d-flex align-items-center pointer"
                                    style={{
                                      width: "172px",
                                    }}
                                  >
                                    {data.picture ? (
                                      <img
                                        src={`${Img_url}${data.picture}`}
                                        alt="top_offer"
                                        className="search_product_img"
                                      />
                                    ) : (
                                      <>
                                        {isFrontImages ? (
                                          <img
                                            src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                            className="search_product_img"
                                            alt="top_offer"
                                          />
                                        ) : (
                                          <CircularProgress color="secondary" />
                                        )}
                                      </>
                                    )}
                                    <p className="search_product_name">
                                      {data.name}
                                    </p>
                                  </div>
                                  {!data?.Mrp ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        disabled
                                        className="  "
                                      >
                                        Not&nbsp;Available
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {!cartItemSend.items.find(
                                        (product) => product.id === data.id
                                      ) ? (
                                        <PrimaryButton
                                          onClick={() => AddToCart(data)}
                                          title="Add "
                                          classNames="  "
                                        />
                                      ) : (
                                        <div className="d-flex">
                                          <Grid className="d-flex align-items-center">
                                            <IconButton
                                              onClick={() => Decrement(data.id)}
                                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                            >
                                              -
                                            </IconButton>
                                            <Typography className="px-2 fs-7 fw-bold">
                                              {data.finalQuantity}
                                            </Typography>
                                            <IconButton
                                              onClick={() => Increment(data)}
                                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                                            >
                                              +
                                            </IconButton>
                                          </Grid>
                                          <IconButton
                                            onClick={() => DeleteCart(data.id)}
                                          >
                                            <img
                                              src={Del}
                                              alt="delete"
                                              width="20px"
                                            />
                                          </IconButton>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </li>
                              ))
                            ) : (
                              <div className="mt-4">
                                <div className="d-flex align-items-center justify-content-between">
                                  <Heading2 title="No Products Found" />
                                  <OutlineButton
                                    title="View All"
                                    onClick={() => navigate("/products")}
                                  />
                                </div>
                                <div className="d-flex align-items-center  flex-wrap">
                                  <Heading2
                                    title="Provide more details "
                                    classNames="mt-2 primary"
                                  />
                                  <IconButton
                                    onClick={
                                      routeToMoreDetails
                                      // () => navigate("/moreDetails")
                                    }
                                    className="mt-2 ms-2"
                                  >
                                    <KeyboardTabIcon
                                      sx={{ color: `var(--secondary)` }}
                                    />
                                  </IconButton>
                                  {/* <PrimaryButton
                      classNames="mt-2"
                      title="Next"
                      onClick={() => navigate("/products")}
                    /> */}
                                </div>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-center">
                      <CircularProgress color="secondary" />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
      <div style={{ margin: " 0 -8px" }}>
        <AnnouncementBar />
      </div>
    </>
  );
};

export default memo(Responsiveheader);

// slider or banner me is order key aarh h usko hiisabse jamane hai
