import React, { useEffect, useState } from "react";

import {
  LoaderButton,
  SecondaryButton,
} from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import StoredVariables, {
  ClearSession,
  GetSession,
} from "../../../constant/Session";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import login_vector from "../../../assets/images/login_vector.jpg";
import CURL, { headers } from "../../../constant";
import { DECODE, Json } from "../../../constant/Util";
import axios from "axios";

const ResetPassword = () => {
  const [Login, setLogin] = useState({
    otp: "",
    new_password: "",
    c_password: "",
  });

  const { otp, new_password, c_password } = Login;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const isLogin = GetSession(StoredVariables.username);

  useEffect(() => {
    isLogin && navigate("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    e.preventDefault();
    setLogin({ ...Login, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    setLoading(true);

    const requestData = {
      otp: otp,
      new_password: new_password,
      c_password: c_password,
    };

    const checkdata = {
      otp: "required",
      new_password: "required|min:8",
      c_password: "required|same:new_password",
    };
    const validation = new Validator(requestData, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);

      // toast.error("fail");
      // await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const url = await CURL("resetpassword");
        console.log(url);
        let header = headers;
        header.Authorization = `Bearer ${Json(
          DECODE(GetSession(StoredVariables.forgot))
        )}`;

        const { status, data } = await axios.post(url, requestData, {
          headers: header,
        });
        if (status === 200) {
          toast.success(data.message);
          ClearSession();
          navigate("/login");
        } else {
          toast.error(data.message);
        }
      }
    }
  };

  return (
    <>
      {" "}
      <form onSubmit={handleSubmit}>
        <div style={{ flexGrow: 1 }} className="container p-4 mt-5">
          <div className="row">
            <div className="col-sm-6">
              <img src={login_vector} alt="log" className="img-fluid" />
            </div>
            <div className="col-sm-6 d-flex flex-column justify-content-center ">
              <input
                name="otp"
                value={Login.otp}
                onChange={handleChange}
                type="text"
                placeholder="Enter Otp"
                className="login_field mb-3"
              />
              <input
                name="new_password"
                value={Login.new_password}
                onChange={handleChange}
                type="password"
                placeholder="New Password"
                className="login_field mb-3"
              />

              <input
                name="c_password"
                value={Login.c_password}
                onChange={handleChange}
                type="password"
                placeholder="Confirm Password"
                className="login_field mb-3"
              />

              {loading ? (
                <LoaderButton classNames="login_btns" title="GET OTP" />
              ) : (
                <SecondaryButton
                  type="submit"
                  classNames="login_btns"
                  title="SUBMIT"
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
