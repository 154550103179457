import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StoredVariables, { SetSession } from "../../constant/Session";
import { GET } from "../../constant/RequestAuthService";

export default function Maintenance() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mode, setMode] = useState(0);
  //
  //CHECKING MAINTENANCE IN WEBSITE
  //
  useLayoutEffect(() => {
    hnadleMaintenance();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const hnadleMaintenance = async () => {
    const response = await GET("maintanance");
    // console.log(response.data.mode);
    if (response.data.mode === 1) {
      // console.log(response.data.message);
      SetSession(StoredVariables.maintenance, response.data.message);
      navigate("/maintenance");
    }
    if (response.data.mode === 0) {
      // console.log(response.data.message);
      SetSession(StoredVariables.maintenance, response.data.message);
      setMode(0);
    }
  };

  useEffect(() => {
    if (mode === 0 && pathname === "/maintenance") {
      navigate("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(pathname === "/maintenance");

  return null;
}
