import React from "react";
import { CopyRightText } from "../../constant/Util";
import { CopyRightTexts } from "../useCustomHooks/useFunctions";

const CopyRight = () => {
  const Text = CopyRightText();
  CopyRightTexts();
  return (
    <>
      <div
        style={{ color: "#fff !Important" }}
        className="copy_right text-white"
      >
        <div
          className=" copy_right_txt text-center py-2 "
          dangerouslySetInnerHTML={{ __html: Text }}
        ></div>
      </div>
    </>
  );
};

export default CopyRight;
