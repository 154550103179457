import { Backdrop, CircularProgress } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GET } from "../../constant/RequestAuthService";
import { DECODE } from "../../constant/Util";
// import frame from "../../assets/images/frame.png";

const Pivacy = () => {
  const [loading, setLoading] = useState(false);
  let { name } = useParams();

  const footerData = useSelector((state) => state.apiReducer.footerData);
  const pageData = footerData.find((data) => data.name === name);
  // console.log(pageData);

  const dispatch = useDispatch();

  useEffect(() => {
    pageData && pageData.data === "" && handleData(name);
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleData = async (pageName) => {
    setLoading(true);
    const response1 = await GET("pages", { id: pageName });
    if (response1.status === 200) {
      setLoading(false);
      let data = DECODE(response1.data);

      const sendData = {
        data,
        name,
      };

      await dispatch({
        type: "getFooterData",
        payload: sendData,
      });
    }
    setLoading(false);
  };

  // console.log(text);
  return (
    <>
      {loading === true && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div className="container my-4 ">
        <div
          className={` ${
            footerData && pageData ? "border_icons_cat shadow-sm" : ""
          }   position-relative overflow-hidden`}
          style={{ paddingBottom: "70px" }}
        >
          <div className="custom-shape"></div>
          <div className="custom-shape2"></div>
          {/* <img className="footer_bg img-fluid" src={frame} /> */}
          {/* <div className="footer_bg">{" "} */}
          {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="footer_bg"
            >
              <path
                fill="#2e7d32"
                fill-opacity="1"
                d="M0,288L1440,160L1440,320L0,320Z"
              ></path>
            </svg> */}
          {/* </div> */}
          {footerData && pageData ? (
            <div dangerouslySetInnerHTML={{ __html: pageData.data }}></div>
          ) : (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Pivacy);
