import { Buffer } from "buffer";
import { useSelector } from "react-redux";
import StoredVariables, { GetSession } from "./Session";
// Base 64 Encode
export const ENCODE = (code) =>
  Buffer.from(
    `MedAppEn-Coding-$2y.S/${Buffer.from(`${code}`).toString(
      "base64"
    )}/$2y.Dev-Mobitplus-MedAppEn-Coding`
  ).toString("base64");

// Base 64 Decode
export const DECODE = (code) =>
  Buffer.from(
    `${Buffer.from(`${code}`, "base64").toString("ascii")}`.split("/")[1],
    "base64"
  ).toString("ascii");

// reload functionallty
export const Reload = () => window.location.reload();

// json to String
export const Stringify = (json) => JSON.stringify(json);

// string to json
export const Json = (string) => JSON.parse(string);

//check network connection
export const IsOnline = navigator.onLine;

//UserLogin
export const UserLogin = () => {
  return GetSession(StoredVariables.username);
};

//
//UseSelectors
//

export const CartItem = () => {
  return useSelector((state) => state.cartsReducer.items);
};

export const CartItemSend = () => {
  return useSelector((state) => state.cartsReducer);
};

export const AllProduct = () => {
  return useSelector((state) => state.cartsReducer.allProduct);
};

export const NewAllProduct = () => {
  return useSelector((state) => state.cartsReducer.moreProduct);
};

export const SearchProduct = () => {
  return useSelector((state) => state.cartsReducer.searchProduct);
};

export const SubCategoryProduct = () => {
  return useSelector((state) => state.cartsReducer.subCategoryProducts);
};
export const CategoryProducts = () => {
  return useSelector((state) => state.cartsReducer.categoryProduct);
};

export const HomeProduct = () => {
  return useSelector((state) => state.cartsReducer.homeProduct);
};
export const FeatureProduct = () => {
  return useSelector((state) => state.cartsReducer.featuredProduct);
};

export const FeatureProducts = () => {
  return useSelector((state) => state.cartsReducer.featuredProducts);
};

export const WishLoad = () => {
  return useSelector((state) => state.apiReducer.load);
};

export const NextpageUrl = () => {
  return useSelector((state) => state.cartsReducer.nextProductLinks);
};

export const NextFeaturedpageUrl = () => {
  return useSelector((state) =>
    state?.cartsReducer?.nextFeatureProductLinks
      ? state?.cartsReducer?.nextFeatureProductLinks
      : null
  );
};
export const NextSearchpageUrl = () => {
  return useSelector((state) => state.cartsReducer.nextSearchProductLinks);
};

export const SavedWishList = () => {
  return useSelector((state) => state.userReducer.wishList);
};

export const CopyRightText = () => {
  return useSelector((state) => state.apiReducer.copyRight);
};
