import React, { useEffect } from "react";
import "./notification.scss";
import { Heading1, Heading2 } from "../../components/heading/Heading";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../../constant/RequestAuthService";
import { CircularProgress, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Img_url } from "../../constant";

const Notification = () => {
  const notificationCount = useSelector(
    (state) => state.notificationReducer.count
  );
  const notificationMsg = useSelector((state) => state.notificationReducer.msg);
  const dispatch = useDispatch();

  useEffect(() => {
    readNotification();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const readNotification = async () => {
    await GET("notify/read");
  };

  useEffect(() => {
    typeof notificationCount === "object" && getNotificationCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotificationCount = async () => {
    const response = await GET("notify/count");
    if (response.status === 200) {
      dispatch({ type: "notifCount", payload: response.data.toString() });
    }
  };

  useEffect(() => {
    Array.isArray(notificationMsg) === false && getNotificationMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(Array.isArray(notificationMsg));
  const getNotificationMessage = async () => {
    const response = await GET("notify/get");
    if (response.status === 200) {
      // console.log(response.data);
      dispatch({ type: "notifmssg", payload: response.data });
    }
  };
  const navigate = useNavigate();
  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  const SkeletonLoader = () => {
    return [...Array(5).keys()].map((data, i) => (
      <div key={i} className="row ">
        <div className="col-12 col-md-6 col-lg-4 col-xl-4 ">
          <div
            className={` order_main_div mt-3 mx-2 d-flex align-items-center justify-content-between br_8`}
            style={{ gap: "15px" }}
          >
            {" "}
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
        </div>
      </div>
    ));
  };

  // console.log(notificationMsg);
  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="container">
        <div>
          <div className="col-sm-12">
            <div className="border_bottom_with_padding_in_product pb-4 ">
              <Heading1 title="Notifications" classNames="mt-2" />
            </div>
          </div>
          {notificationMsg && typeof notificationMsg === "object" ? (
            <>
              {notificationMsg.length === 0 ? (
                <div className="my-5">
                  <div className="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto">
                    <div className="order_main_div mt-3  py-4 d-flex align-items-center justify-content-between flex-wrap ">
                      <Heading2 title="No Notifications Yet" />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="d-flex flex-wrap"
                    style={{ margin: "24px -8px" }}
                  >
                    {notificationMsg.map((data, i) => (
                      <div key={i} className="col-12 col-md-6 col-xl-4 ">
                        <div
                          className={`  ${
                            data.is_read === 0 && "isRead"
                          } order_main_div mt-3 mx-2 d-flex align-items-center justify-content-between br_8 p-2`}
                          style={{ gap: "15px" }}
                        >
                          <div
                            className="d-flex align-items-center pointer"
                            style={{ gap: "10px" }}
                            onClick={() =>
                              navigate(`/productDetails/${data.product_id}`)
                            }
                          >
                            <div>
                              {data?.product?.image ? (
                                <>
                                  <img
                                    src={`${Img_url}${data.product.image.name}`}
                                    style={{
                                      height: "30px",
                                      display: "block",
                                    }}
                                    alt="details"
                                    className="img-fluid br_8"
                                  />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {isFrontImages ? (
                                    <img
                                      src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                      className="img-fluid  br_8"
                                      alt="top_offer"
                                      style={{
                                        height: "30px",
                                        display: "block",
                                      }}
                                    />
                                  ) : (
                                    <CircularProgress color="secondary" />
                                  )}
                                </>
                              )}
                            </div>

                            <div>
                              <p className="order_item_main_head fs_12 fw_700">
                                {data.product.name}
                              </p>
                            </div>
                          </div>
                          <p
                            className={` ${
                              data.status_id === 1 ? "primary" : "secondary"
                            } order_item_main_head fs_12 fw_700`}
                          >
                            {data?.status?.status}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="mt-4">
                {" "}
                <SkeletonLoader />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
