import React, { memo } from "react";
import { Heading1 } from "../../../components/heading/Heading";
// import { HomeProduct } from "../../../constant/Util";
import ViewAllButton from "../../../sharedComponents/viewallButton/ViewAllButton";
import ProductCarousel from "../productCarousel/ProductCarousel";
// import { useSelector } from "react-redux";
const BestSellers = () => {
  const list = localStorage.getItem("recentSearchList");

  const listData = list && list ? JSON.parse(list && list) : "";
  return (
    <>
      <div className="container mt-3">
        <div className="row shadow_gr">
          {listData && listData !== "" ? (
            <div className="col-sm-12">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <Heading1 title="Resent Searches" />
                <ViewAllButton />
              </div>

              <ProductCarousel data={listData} />
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
    </>
  );
};

export default memo(BestSellers);
