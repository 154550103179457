import { IconButton } from "@mui/material";
import React, { memo } from "react";
import "./Fav.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Fav = ({ open, setOpen }) => {
  //   const [open, setOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className={open ? "full_w_bg_v" : "full_w_bg_h"}
      ></div>
      <div
        className={
          open ? "overlay_bg_fav open_cart" : "overlay_bg_fav close_cart"
        }
      >
        <IconButton
          sx={{ color: `var(--secondary)` }}
          onClick={() => setOpen(!open)}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
    </>
  );
};

export default memo(Fav);
