import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Img_url } from "../../constant";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import StarRateIcon from "@mui/icons-material/StarRate";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import ShareIcon from "@mui/icons-material/Share";
import { PrimaryButton, SecondaryButton } from "../buttons/Button";
import "./Product.css";

import { CartItemSend, SavedWishList } from "../../constant/Util";

const ProductCard = ({
  data,
  HandlewishtList,
  AddToCart,
  Decrement,
  Increment,
  DeleteCart,
}) => {
  // console.log(data);
  const cartItemSend = CartItemSend();
  const getSavedWishList = SavedWishList();
  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);
  const load = useSelector((state) => state.apiReducer.load);

  const navigate = useNavigate();
  return (
    <Grid
      className="d-flex align-items-center flex-column  top_offer_card shadow_box_s  br_8 product_box"
      style={{ height: "310px" }}
    >
      <Grid
        className="w-100 px-3"
        onClick={() => navigate(`/productDetails/${data.id}`)}
        sx={{ overflow: "hidden" }}
      >
        {data.picture ? (
          <img
            src={`${Img_url}${data.picture}`}
            className={`img-fluid top_offer_card_inner_div  br_8 ${
              cartItemSend.items.find((product) => product.id === data.id) &&
              "alpha"
            } `}
            alt="top_offer"
            style={{
              height: "150px",
              display: "block",
              margin: "10px auto",
            }}
          />
        ) : (
          <>
            {isFrontImages && isFrontImages.length !== 0 ? (
              <img
                src={`${Img_url}${isFrontImages[3].credencial_content}`}
                className={`img-fluid top_offer_card_inner_div br_8 ${
                  cartItemSend.items.find(
                    (product) => product.id === data.id
                  ) && "alpha"
                } `}
                alt="top_offer"
                style={{
                  height: "150px",
                  display: "block",
                  margin: "10px auto",
                }}
              />
            ) : (
              <CircularProgress color="secondary" />
            )}
          </>
        )}
        <p className="mt-2  fs_16 fw_500 Product_heading custom-truncate">
          {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
        </p>
        {/* {data?.para && <p className="mt-2  fs_15 fw_700 ">{data.para}</p>} */}
        <div className="d-flex justify-content-between align-items-center  pb-3 flex-wrap">
          {data.Mrp && (
            <div className="d-flex align-items-center">
              <p className="  primary_col  fw_600   mt-2">
                Rs {data.BillPrice}
              </p>
              <span
                className="ms-2 mt-2 fw_700"
                style={{
                  textDecoration: "line-through",
                  color: `var(--secondary)`,
                  marginTop: "2px",
                }}
              >
                {data?.Mrp}
              </span>
            </div>
          )}
        </div>{" "}
        {/* <span className="off_text_percent mt-2">
          {100 - (data?.BillPrice / data?.Mrp).toFixed(2) * 100}% OFF
        </span> */}
      </Grid>

      <Grid className="right_div_top_offer">
        {data.is_fetured === 1 && <StarRateIcon sx={{ color: "#E6A300" }} />}
        {cartItemSend.items.find((product) => product.id === data.id) && (
          <Grid className="mt-2">
            <TaskAltIcon sx={{ color: "var(--primary)" }} />
          </Grid>
        )}
      </Grid>
      {data?.Mrp && data?.BillPrice && (
        <Grid className="left_div_top_offer">
          <span className="off_text_percent mt-2">
            Up to {100 - (data?.BillPrice / data?.Mrp).toFixed(2) * 100}% OFF
          </span>{" "}
        </Grid>
      )}

      <Grid className="w-100 mb-2 mt-auto d-flex align-items-center justify-content-start">
        {!data?.Mrp ||
        !data?.total_qty ||
        data.total_qty === "0" ||
        data.total_qty === 0 ||
        data.total_qty === "0.00" ? (
          <>
            <Button variant="contained" disabled className=" mx-3 w-100">
              Not Available
            </Button>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between w-100">
              {!cartItemSend.items.find((product) => product.id === data.id) ? (
                <>
                  <PrimaryButton
                    onClick={() => AddToCart(data)}
                    title="Add To Cart"
                    classNames=" mx-3 w-100"
                  />
                  {/* <PrimaryButton title="add" /> */}
                </>
              ) : (
                <div className="d-flex">
                  <Grid className="d-flex align-items-center">
                    <IconButton
                      onClick={() => Decrement(data.id)}
                      className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                    >
                      -
                    </IconButton>
                    <Typography className="px-2 fs-7 fw-bold">
                      {data.finalQuantity}
                    </Typography>
                    <IconButton
                      onClick={() => Increment(data)}
                      className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                    >
                      +
                    </IconButton>
                  </Grid>
                  <SecondaryButton
                    onClick={() => DeleteCart(data.id)}
                    title="Remove"
                    classNames=" w-100"
                  />
                </div>
              )}
              {/* {load === data.id ? (
                <IconButton className="top_oofer_icon me-2">
                  <Skeleton variant="circular" width={20} height={20} />
                </IconButton>
              ) : ( */}
              <IconButton
                onClick={() => HandlewishtList(data.id)}
                className="top_oofer_icon me-2"
              >
                {load === data.id ? (
                  <Skeleton variant="circular" width={25} height={25} />
                ) : getSavedWishList &&
                  getSavedWishList.find(
                    (item) => item.product_id === data.id
                  ) ? (
                  <FavoriteIcon sx={{ color: `var(--secondary)` }} />
                ) : (
                  <FavoriteBorderIcon />
                )}
                {/* {getSavedWishList &&
                  getSavedWishList.find(
                    (item) => item.product_id === data.id
                  ) ? (
                    <FavoriteIcon sx={{ color: `var(--secondary)` }} />
                  ) : (
                    <FavoriteBorderIcon />
                  )} */}
              </IconButton>
              {/* )} */}
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductCard;
