import React, { memo, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAuthentication } from "../../constant/RequestAuthService";
import { NextSearchpageUrl, SearchProduct } from "../../constant/Util";
import { RemoveSession } from "../../constant/Session";

import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductCardLoader } from "../../components/skeletons/Loader";
import { Heading2 } from "../../components/heading/Heading";
import ViewAllButton from "../../sharedComponents/viewallButton/ViewAllButton";
import noProduct from "../../assets/images/noProduct.png";
import ProductCard from "../../components/productCard/ProductCard";
import { useCartFunctanilty } from "../../components/useCustomHooks/useFunctions";

import SmScreenSearch from "./SmScreenSearch";

const CategoryFilter = () => {
  const dispatch = useDispatch();
  const moreProduct = SearchProduct();
  const nextPageUrl = NextSearchpageUrl();
  const hasMore = true;
  const loading = useSelector((state) => state.cartsReducer.loading);

  const { AddToCart, Increment, Decrement, DeleteCart, HandlewishtList } =
    useCartFunctanilty();

  const matches = useMediaQuery("(max-width:767px)");

  const matches2 = useMediaQuery("(min-width:767px)");

  useEffect(() => {
    return () => {
      RemoveSession("sendData");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoredata = () => {
    setTimeout(() => {
      getallNextProduct(nextPageUrl);
    }, 500);
  };

  const getallNextProduct = async (nextPageUrl) => {
    if (nextPageUrl !== null && nextPageUrl !== "null") {
      const { status, data } = await axios.post(
        nextPageUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${GetAuthentication(true).token}`,
            "Content-type": "application/json",
          },
        }
      );
      if (status === 200) {
        // console.log("run");
        dispatch({
          //   type: "AddProductinallProduct",
          type: "AddProductinSearchProduct",
          payload: data.data,
        });
      }
    }
  };

  const getValues = useSelector((state) => state.cartsReducer.getAllvalues);

  useEffect(() => {
    const prAdd = async () => {
      await dispatch({ type: "IsProductAdded" });
    };
    prAdd();
  }, [getValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const Loader = () => {
    return (
      <div
        style={{ height: "200px" }}
        className="d-flex align-items-center justify-content-center"
      >
        <CircularProgress color="secondary" />
      </div>
    );
  };
  const loading2 = useSelector((state) => state.cartsReducer.reloadLoading);

  const HtmlPage = () => {
    return (
      <div className="container-fluid flex-grow-1 h-100">
        <div className="row gx-4 px-3 px-xs-0">
          {moreProduct.length === 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between flex-wrap p-0">
                <Heading2
                  title="No Matching Product at the moment"
                  classNames="mt-2"
                />
                <div className="mt-2">
                  <ViewAllButton />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center ">
                <img
                  src={noProduct}
                  alt="noProduct"
                  className="img-fluid"
                  style={{ maxWidth: `${matches2 === true && "600px"}` }}
                />
              </div>
            </>
          )}
          {moreProduct.map((data, i) => (
            <div
              key={i}
              className="col-md-4 col-xl-3 col-xxl-2 py-2 px-0 p-md-3"
            >
              {/* <Grid className="top_offer_card br_8">
                <Grid onClick={() => navigate(`/productDetails/${data.id}`)}>
                  <Grid className="mx-2">
                    {data.picture !== null ? (
                      <img
                        src={`${Img_url}${data.picture}`}
                        // src={data.product.picture[0].name}
                        className={`img-fluid top_offer_card_inner_div br_8 ${
                          cartItemSend.items.find(
                            (product) => product.id === data.id
                          ) && "alpha"
                        } `}
                        alt="top_offer"
                        style={{
                          height: "150px",
                          display: "block",
                          margin: "10px auto",
                        }}
                      />
                    ) : (
                      <>
                        {isFrontImages ? (
                          <img
                            src={`${Img_url}${isFrontImages[3].credencial_content}`}
                            className={`img-fluid top_offer_card_inner_div br_8 ${
                              cartItemSend.items.find(
                                (product) => product.id === data.id
                              ) && "alpha"
                            } `}
                            alt="top_offer"
                            style={{
                              height: "150px",
                              display: "block",
                              margin: "10px auto",
                            }}
                          />
                        ) : (
                          <CircularProgress color="secondary" />
                        )}
                      </>
                    )}
                  </Grid>
                  <p className="mt-2  mx-3 fs_14 fw_500">{data.name}</p>
                  <p className="mt-2  fs_15 fw_700 ">{data.para}</p>
                  <div className="d-flex justify-content-between align-items-center mt-2 pb-3">
                    {data?.Mrp && (
                      <p className=" mx-3 primary_col fs_14 fw_600 mt-1">
                        Rs {data?.Mrp}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid className="left_div_top_offer">
                  <br />

                  <IconButton
                    onClick={() => handlewishtList(data.id)}
                    className="top_oofer_icon"
                  >
                    {getSavedWishList &&
                    getSavedWishList.find(
                      (item) => item.product_id === data.id
                    ) ? (
                      <FavoriteIcon sx={{ color: `var(--secondary)` }} />
                    ) : (
                      <FavoriteBorderIcon />
                    )}
                  </IconButton>
                  <br />
                  <IconButton className="top_oofer_icon">
                    <ShareIcon />
                  </IconButton>
                </Grid>
                {cartItemSend.items.find(
                  (product) => product.id === data.id
                ) && (
                  <Grid className="Tick_div">
                    <TaskAltIcon sx={{ color: "var(--primary)" }} />
                  </Grid>
                )}

                <Grid className="right_div_top_offer">
                  {data.is_fetured === 1 && (
                    <StarRateIcon sx={{ color: "#E6A300" }} />
                  )}
                </Grid>
                <Grid className="w-100 mb-4 d-flex align-items-center justify-content-start">
                  {!data?.Mrp ? (
                    <>
                      <Button
                        variant="contained"
                        disabled
                        className=" mx-3 w-100"
                      >
                        Not Available
                      </Button>
                    </>
                  ) : (
                    <>
                      {!cartItemSend.items.find(
                        (product) => product.id === data.id
                      ) ? (
                        <PrimaryButton
                          onClick={() => addToCart(data)}
                          title="Add To Cart"
                          classNames=" mx-3 w-100"
                        />
                      ) : (
                        <div className="d-flex">
                          <Grid className="d-flex align-items-center">
                            <IconButton
                              onClick={() => decrement(data.id)}
                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                            >
                              -
                            </IconButton>
                            <Typography className="px-2 fs-7 fw-bold">
                              {data.finalQuantity}
                            </Typography>
                            <IconButton
                              onClick={() => Increment(data)}
                              className="bg-opacity-10 quantity-btn wh-30px fw-bold fs-5"
                            >
                              +
                            </IconButton>
                          </Grid>
                          <SecondaryButton
                            onClick={() => deleteCart(data.id)}
                            title="Remove"
                            classNames=" w-100"
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </Grid> */}
              <ProductCard
                data={data}
                HandlewishtList={HandlewishtList}
                AddToCart={AddToCart}
                Increment={Increment}
                Decrement={Decrement}
                DeleteCart={DeleteCart}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column">
        {matches === true && <SmScreenSearch />}

        {loading === true || loading2 === true ? (
          <>
            <div className="container mt-3 pt-4">
              <div className="row gx-4 px-3 px-sm-0">
                {[...Array(4).keys()].map((data, i) => (
                  <div key={i} className=" col-md-4 col-xl-3 col-xxl-2 p-0">
                    <ProductCardLoader />
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ flexGrow: 1 }} className="container mt-3">
              {moreProduct ? (
                <>
                  {nextPageUrl !== null ? (
                    <>
                      <InfiniteScroll
                        dataLength={moreProduct.length}
                        next={fetchMoredata}
                        hasMore={hasMore}
                        loader={moreProduct.length !== 0 && <Loader />}
                      >
                        <HtmlPage />
                      </InfiniteScroll>
                    </>
                  ) : (
                    <>
                      <HtmlPage />
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(CategoryFilter);
