import React from "react";
import { Heading2 } from "../../../components/heading/Heading";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { SetSession } from "../../../constant/Session";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const items = [
  { title: "Previous Orders", tab: 2, icon: LocalMallIcon },
  { title: "My Records", tab: 4, icon: LocalHospitalIcon },
];

const AfterLoginCards = (data) => {
  const navigate = useNavigate();
  const navigateActive = (data) => {
    navigate("/loginDashboard");
    SetSession("activetabsMenu", data.tab);
  };
  return (
    <div className="container mt-3  ">
      <div className="row">
        {items.map((data, i) => (
          <div
            key={i}
            onClick={() => navigateActive(data)}
            className="col-sm-6 mt-2 pointer"
          >
            <div className="border_icons_cat d-flex align-items-center justify-content-between product_box shadow_box_s">
              <div className=" d-flex align-items-center ">
                <data.icon
                  className="me-2"
                  sx={{
                    color: ` var(--primary)`,
                  }}
                />
                <Heading2 title={data.title} classNames="primary" />
              </div>
              <NavigateNextIcon className="primary" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AfterLoginCards;
