import { configureStore } from "@reduxjs/toolkit";
// import reduxThunk from "redux-thunk";
import userReducer from "./reducers/userReducer";
import apiReducer from "./reducers/apiReducer";
import cartsReducer from "./reducers/cartsReducer";
import notificationReducer from "./reducers/notificationReducer";
import subCategoriesReducer from "./reducers/subCategoriesReducer";
// import { applyMiddleware } from "redux";
// const middlewares = [reduxThunk];

export const store = configureStore({
  reducer: {
    userReducer,
    apiReducer,
    cartsReducer,
    notificationReducer,
    subCategoriesReducer,
  },
});
