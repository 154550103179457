import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../../constant/RequestAuthService";
import { DECODE } from "../../constant/Util";
import "./announcementBar.scss";

const AnnouncementBar = () => {
  const dispatch = useDispatch();
  const announcement = useSelector((state) => state.apiReducer.AnnouncementBar);

  const GetAnnouncement = async () => {
    const response1 = await GET("pages/announcement");
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "AnnouncementBar", payload: response1.data });
    }
  };

  useEffect(() => {
    if (announcement === "") {
      GetAnnouncement();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(announcement);

  return (
    <>
      {announcement && DECODE(announcement) !== "" && (
        <div className="containerfluid announcement-bar">
          <div className="announcement-bar-inner">
            <marquee>
              {announcement !== "" && (
                <div
                  className=" "
                  dangerouslySetInnerHTML={{ __html: DECODE(announcement) }}
                ></div>
              )}
            </marquee>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementBar;
