import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import ScrollIcons from "../assets/images/ScrollIcons.gif";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import "./Scroll.scss";

export const RouterTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const ScrollIcon = () => {
  return (
    <>
      <KeyboardDoubleArrowUpIcon className="fs-3" />
    </>
  );
};
