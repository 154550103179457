import React, { useState } from "react";

import {
  LoaderButton,
  SecondaryButton,
} from "../../../components/buttons/Button";

import { Link } from "react-router-dom";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { URL } from "../../redux/api/Api";
import * as Validator from "validatorjs";
import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, {
  GetSession,
  SetSession,
  // SetSession,
} from "../../../constant/Session";
// import { Stringify, ENCODE } from "../../constant/Util";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import login_vector from "../../../assets/images/login_vector.jpg";
import { ENCODE, Stringify } from "../../../constant/Util";
const EmailVerify = () => {
  const [Login, setLogin] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const isLogin = GetSession(StoredVariables.username);

  useEffect(() => {
    isLogin && navigate("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    e.preventDefault();
    setLogin({ ...Login, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    setLoading(true);

    const checkdata = {
      email: "required|email",
    };
    const validation = new Validator(Login, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);

      // toast.error("fail");
      // await Validation(validation.errors.errors);
    } else {
      const response = await POST("forgotpassword", Login);

      if (response.status === 200) {
        setLoading(true);
        toast.success(response.message);
        SetSession(
          StoredVariables.forgot,
          ENCODE(Stringify(response.data.temp_token))
        );
        navigate("/resetpassword");
      } else {
        toast.error(response.message);
        setLoading(true);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="container p-4 mt-5">
        <div className="row">
          <div className="col-sm-6">
            <img src={login_vector} alt="log" className="img-fluid" />
          </div>
          <div className="col-sm-6 d-flex flex-column justify-content-center ">
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <input
                name="email"
                value={Login.email}
                onChange={handleChange}
                type="email"
                placeholder="Enter Email *"
                className="login_field mb-3"
              />
              <br />
              <div className="d-flex flex-wrap justify-content-between align-items-center login_btns">
                <Link
                  to="/login"
                  style={{ textDecoration: "none" }}
                  className="fs_14 fw_600 primary hover_a ms-2 "
                >
                  {" "}
                  Signin
                </Link>{" "}
                <Link
                  to="/register"
                  style={{ textDecoration: "none" }}
                  className="fs_14 fw_600 primary hover_a ms-2 "
                >
                  {" "}
                  Register
                </Link>{" "}
              </div>

              {loading ? (
                <LoaderButton classNames="login_btns mt-3" title="GET OTP" />
              ) : (
                <SecondaryButton classNames="login_btns mt-3" title="GET OTP" />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
