import React from "react";
import { useNavigate } from "react-router-dom";
import { OutlineButton } from "../../components/buttons/Button";

const ViewAllButton = ({ featured }) => {
  const navigate = useNavigate();
  return (
    <>
      <OutlineButton
        title="View More"
        onClick={
          () =>
            navigate(`${featured ? "/products?is_featured=1" : "/products"}`)

          // (`/products`)
        }
      />
    </>
  );
};

export default ViewAllButton;
