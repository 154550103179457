import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { POST } from "../../../constant/RequestAuthService";
import login_vector from "../../../assets/images/login_vector.jpg";
import { PrimaryButton } from "../../../components/buttons/Button";

const VerifyEmail = () => {
  const [message, setMessage] = useState("Please wait we working on it.");
  const [message2, setMessage2] = useState("");
  const [toggleButton, setToggleButton] = useState(false);
  const navigate = useNavigate();
  const { data, email } = useParams();
  console.log(data, email);

  const verifiedUserEmail = async (data) => {
    const requestData = {
      otp: data.slice(data.length - 4),
      email: email,
    };
    const response = await POST("email_varify", requestData);
    if (response.status === 200) {
      toast.success(response.message);
      await setMessage("SuccessFully Verified");
      await setMessage2(response.message);
    } else {
      toast.error(response.message);
      await setMessage(" Somthing Went Wrong");
      await setMessage2(response.message);
    }
    setToggleButton(!toggleButton);
  };

  useEffect(() => {
    verifiedUserEmail(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className="container p-4 mt-5">
        <div className="row">
          <div className="col-sm-6">
            {" "}
            <img src={login_vector} alt="log" className="img-fluid" />
          </div>
          <div className="col-sm-6">
            <div className="text-center mt-5">
              <h2>{message}</h2>
              <div>{message2}</div>
              {toggleButton && (
                <PrimaryButton
                  classNams="my-3 mx-auto"
                  variant="contained"
                  onClick={() => navigate("/")}
                  title="click here"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
