import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Heading1, Heading2 } from "../../components/heading/Heading";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { GET, POST } from "../../constant/RequestAuthService";
import { Img_url } from "../../constant";
import "./WishList.css";
import {
  SecondaryButton,
  PrimaryButton,
  LoaderButton,
} from "../../components/buttons/Button";
import { CircularProgress } from "@mui/material";
import StoredVariables, { GetSession } from "../../constant/Session";
import { toast } from "react-toastify";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

export default function SimpleContainer() {
  const getSavedWishList = useSelector((state) => state.userReducer.wishList);
  const [removed, setRemoved] = useState("");
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState("");
  const [input, setInput] = useState("");
  const [wishList, setWishList] = useState(getSavedWishList);

  useEffect(() => {
    setWishList(getSavedWishList);
  }, [getSavedWishList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    removed === "remove" && getWishList();
  }, [removed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWishList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const getWishList = async () => {
    const response1 = await GET("product/faviorate");
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "SavedWishList", payload: response1.data });
    }
  };

  const navigate = useNavigate();

  const isLogin = GetSession(StoredVariables.username);

  const handlewishtList = async (data) => {
    setSelect(data);

    if (!isLogin) {
      navigate("/login");
      toast.error("Please login first");
    } else {
      const sendData = {
        product_id: data,
      };

      setLoading(true);

      const response = await POST("product/faviorate", sendData);

      if (response.status === 200) {
        toast.success(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
        setLoading(false);
      }
      if (response.status === 203) {
        toast.error(response.message, { position: "bottom-left" });
        setRemoved("remove");
        setTimeout(() => {
          setRemoved("");
        }, 300);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    removed === "remove" && getWishList();
  }, [removed]); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(loading);
  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  useEffect(() => {
    if (input.length > 1) {
      const filerArr = wishList.filter((x) =>
        x.product.name.toLowerCase().includes(input.toLowerCase())
      );
      setWishList(filerArr);
    } else {
      setWishList(getSavedWishList);
    }
  }, [input]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className=" d-flex flex-column">
        <div className="flex-grow-1">
          <div className="container">
            <div>
              <div className="col-sm-12">
                <div className="border_bottom_with_padding_in_product pb-4 ">
                  <Heading1 title="My Wish List" classNames="mt-2" />
                </div>
              </div>
              {wishList && typeof wishList === "object" ? (
                <>
                  {wishList.length === 0 ? (
                    <div className="my-5">
                      <div className="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto">
                        <div className="mt-3  py-4 d-flex align-items-center justify-content-center flex-wrap gap-3">
                          <Heading2 title="No Wishlist Yet" />
                          <PrimaryButton
                            title="Create Now"
                            onClick={() => navigate("/")}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-4 mt-4">
                        <div className="relative">
                          <input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            style={{ paddingLeft: "20px" }}
                            className="search_input_field "
                            placeholder="Search Wish Lists here"
                          />
                          <IconButton className="icon seacrh_icon_input_inside">
                            <SearchIcon className=" pink_icon" />
                          </IconButton>
                        </div>
                      </div>
                      <div className="wish_list_inner">
                        <AnimatePresence>
                          {wishList.map((data, i) => (
                            <div key={i} className="col-12 col-md-6 col-xl-4">
                              <motion.div
                                layout
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0 }}
                                transition={{ duration: 0.5 }}
                                className="order_main_div mt-3 mx-2 d-flex align-items-center justify-content-between p-2"
                                style={{ gap: "15px" }}
                              >
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/productDetails/${data.product_id}`
                                    )
                                  }
                                  className="d-flex align-items-center pointer"
                                  style={{ gap: "10px" }}
                                >
                                  {data.product.picture.length > 0 ? (
                                    <>
                                      <img
                                        src={`${Img_url}${data.product.picture.map(
                                          (data) => data.name
                                        )}`}
                                        alt="flipkart_img"
                                        width="80px"
                                        className="pt-2"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {isFrontImages ? (
                                        <img
                                          src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                          className="list-img top_offer_card_inner_div br_8"
                                          alt="top_offer"
                                          width="80px"
                                        />
                                      ) : (
                                        <CircularProgress color="secondary" />
                                      )}
                                    </>
                                  )}

                                  <div>
                                    <p className="order_item_main_head">
                                      {data.product.name}
                                    </p>
                                    <p className="order_item_Sec_head mt-1">
                                      {data.product.para}
                                    </p>
                                  </div>
                                </div>

                                <div className=" my-auto">
                                  {select === data.product_id ? (
                                    <LoaderButton title="hello" />
                                  ) : (
                                    <SecondaryButton
                                      disabled={loading}
                                      onClick={() =>
                                        handlewishtList(data.product_id)
                                      }
                                      title="Remove"
                                    />
                                  )}
                                </div>
                              </motion.div>
                            </div>
                          ))}{" "}
                        </AnimatePresence>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div
                    style={{ height: "500px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
