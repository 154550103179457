import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  frontImage: [],
  slider: [],
  categoryList: [],
  banner: [],
  prescription: [],
  HeaderMenus: [],
  footerData: [
    {
      name: "privacy_policy",
      data: "",
    },
    {
      name: "terms_conditions",
      data: "",
    },
    {
      name: "faqs",
      data: "",
    },
    {
      name: "about_us",
      data: "",
    },
    {
      name: "return_refund",
      data: "",
    },
    {
      name: "anti_scam",
      data: "",
    },
  ],
  paymentMethod: [],
  orderStat: [],
  copyRight: [],
  ProductHeading: "",
  AnnouncementBar: "",
  loading: false,
  date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
  load: "",
  productLoad: false,
};

const apiReducer = createReducer(initialState, {
  wishLoadtrue: (state, action) => {
    state.load = action.payload;
  },
  wishLoadfalse: (state, action) => {
    state.load = "";
  },
  LoadingStart: (state, action) => {
    state.loading = true;
  },
  LoadingStop: (state, action) => {
    state.loading = false;
  },
  PLoadingStart: (state, action) => {
    state.productLoad = true;
  },
  PLoadingStop: (state, action) => {
    state.productLoad = false;
  },
  sliderAd: (state, action) => {
    state.slider = action.payload;
  },
  categoryList: (state, action) => {
    state.categoryList = action.payload;
  },

  getBanner: (state, action) => {
    state.banner = action.payload;
  },
  getFooterData: (state, action) => {
    const savedList = state.footerData.find(
      (data) => data.name === action.payload.name
    );
    if (savedList) {
      savedList.data = action.payload.data;
    }
  },
  getPrescription_data: (state, action) => {
    state.prescription = action.payload;
  },
  paymentMethod: (state, action) => {
    state.paymentMethod = action.payload;
  },
  orderStatus: (state, action) => {
    state.orderStat = action.payload;
  },
  CopyRightText: (state, action) => {
    state.copyRight = action.payload;
  },
  HeadingText: (state, action) => {
    state.ProductHeading = action.payload;
  },
  AnnouncementBar: (state, action) => {
    state.AnnouncementBar = action.payload;
  },
  getFrontImage: (state, action) => {
    state.frontImage = action.payload;
  },
  AllHeaderMenus: (state, action) => {
    state.HeaderMenus = action.payload;
  },
});

export default apiReducer;
