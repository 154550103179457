import React, { memo, useState, useLayoutEffect } from "react";
import "./Footer.scss";
import { footer } from "../../data/Data";
import { Link } from "react-router-dom";

import Playstore from "../../assets/images/playstore.png";
import Appstore from "../../assets/images/appstore.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CopyRight from "./CopyRight";

import { CircularProgress } from "@mui/material";
import { Img_url } from "../../constant";
import { useSelector } from "react-redux";
import { GET } from "../../constant/RequestAuthService";
import { DECODE } from "../../constant/Util";

const Footer = () => {
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");

  const getAddressAndNumber = async () => {
    const response = await GET("pages", { id: "my_address" });
    if (response?.status === 200) {
      await setAddress(DECODE(response?.data));
    }
    const response2 = await GET("pages", { id: "contact_number" });
    if (response2?.status === 200) {
      await setNumber(DECODE(response2?.data));
    }
  };

  useLayoutEffect(() => {
    getAddressAndNumber();
  }, []);
  // console.log(copyRIghttext);
  const isFrontImages = useSelector((state) => state.apiReducer.frontImage);
  return (
    <>
      <div className="main_footer ">
        <div className="container mt-3 p-4 pb-0">
          <div className="row  ">
            <div className="main_footer ">
              <div className="row footer_padd text-white">
                <div className="col-md-3 px-0 px-sm-3 text-white">
                  <div className="d-flex d-flex flex-wrap  align-items-center">
                    {isFrontImages && isFrontImages.length !== 0 ? (
                      <img
                        src={
                          isFrontImages &&
                          `${Img_url}${isFrontImages[1].credencial_content}`
                        }
                        alt="logo"
                        className="img-fluid"
                        width="100px"
                      />
                    ) : (
                      <CircularProgress color="secondary" />
                    )}
                  </div>
                  <p className="footer_txt mt-2">
                    <div dangerouslySetInnerHTML={{ __html: address }}></div>
                  </p>
                  <Link
                    style={{ textDecoration: "none", color: `var(--primary)` }}
                    className=" mt-2 fs_15 fw_500 d-flex align-items-center show_num"
                    to="https://api.whatsapp.com/send/?phone=918019779977&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    Need help?
                    <WhatsAppIcon
                      className="mx-1 fs-5"
                      style={{
                        marginTop: "-1px",
                      }}
                    />
                    <span className="whatsapp_num">
                      {" "}
                      <p dangerouslySetInnerHTML={{ __html: number }}></p>{" "}
                    </span>
                  </Link>
                </div>
                <div className="col-md-3 px-0 px-sm-3">
                  <h5
                    className="mb-md-3 mb-2 mt-4 mt-md-0"
                    style={{ color: "#000" }}
                  >
                    About Us
                  </h5>
                  {footer.about.map((about, i) => (
                    <div key={i}>
                      <Link
                        className="footer_txt footer_link"
                        to={`/pages/${about.path}`}
                      >
                        {about.title}
                      </Link>
                      <br />
                    </div>
                  ))}
                </div>
                <div className="col-md-3 px-0 px-sm-3">
                  <h5
                    className="mb-md-3 mb-2 mt-4 mt-md-0"
                    style={{ color: "#000" }}
                  >
                    We are here to Help
                  </h5>
                  {footer.help.map((about, i) => (
                    <div key={i}>
                      <Link
                        className="footer_txt footer_link"
                        to={`/pages/${about.path}`}
                      >
                        {about.title}
                      </Link>
                      <br />
                    </div>
                  ))}
                </div>
                <div className="col-md-3 px-0 px-sm-3">
                  <h5
                    className="mb-md-3 mb-2 mt-4 mt-md-0"
                    style={{ color: "#000" }}
                  >
                    Get Our App
                  </h5>
                  {/* <p className="footer_txt">{footer.payment.desc}</p>
                  <img
                    src={footer.payment.img}
                    alt="paymnt_img"
                    className="img-fluid"
                    style={{ marginLeft: "-10px" }}
                  /> */}
                  <div className="app-link">
                    <a
                      href="https://play.google.com/store/apps/details?id=medapp.mobitplus.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Playstore} alt="" width="170" />
                    </a>
                    <a href="#hjh">
                      <img src={Appstore} alt="" width="170" />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="bt_green br_8"></div> */}
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </>
  );
};

export default memo(Footer);
