import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GET } from "../../constant/RequestAuthService";
import { Img_url } from "../../constant";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { Stringify } from "../../constant/Util";
import { SetSession } from "../../constant/Session";

const CategoriesMenu = () => {
  const dispatch = useDispatch();
  const AllHeaderMenus = useSelector((state) => state.apiReducer.HeaderMenus);

  const [enter, setEnter] = useState("");

  const handleSubCategory = async (x) => {
    setEnter(x.id);
  };

  const handleLeaveLine = () => {
    setEnter("");
  };

  useEffect(() => {
    if (!AllHeaderMenus || AllHeaderMenus.length === 0) {
      getAllData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllData = async () => {
    const response1 = await GET("categories");
    if (response1.status === 200) {
      // console.log("run");
      dispatch({ type: "AllHeaderMenus", payload: response1.data });
    }
  };

  // console.log(AllHeaderMenus);

  const SubCategoryProduct = (data) => {
    SetSession("C_ID", Stringify(data.category_id));
    navigate(`/subCategories/${data.id}/${data.category_id}`);
  };

  const navigate = useNavigate();
  return (
    <>
      <ul className="cat-head">
        {AllHeaderMenus && typeof AllHeaderMenus === "object" ? (
          <>
            {AllHeaderMenus.length === 0 ? (
              <>""</>
            ) : (
              <>
                {AllHeaderMenus.slice(0, 6).map((data, ind) => (
                  <li
                    onMouseEnter={() => handleSubCategory(data)}
                    onMouseLeave={handleLeaveLine}
                    key={ind}
                  >
                    <div className="cat-menu-list">
                      <img
                        src={`${Img_url}${data.picture}`}
                        alt=""
                        className="cat-logo"
                      />
                      <p className="hover_p_cat">{data.name}</p>
                      <ExpandMoreIcon
                        className={` ${
                          enter === data.id && "icon_r_180"
                        } cat-expand-icon`}
                      />
                    </div>
                    <div
                      onMouseLeave={() => setEnter("")}
                      className={` ${
                        enter === data.id ? "" : "d-none"
                      }  cat-menu-drop`}
                    >
                      {enter && (
                        <ul>
                          <li
                            style={{ color: `var(--primary)` }}
                            onClick={() => navigate(`/subCategory/${data.id}`)}
                            className="d-flex align-items-center pointer"
                          >
                            All {data.name}{" "}
                            <NavigateNextIcon
                              sx={{ fontSize: "15px", marginTop: "2px" }}
                            />
                          </li>

                          {data.sub_category.map((x, i) => (
                            <li
                              className="pointer"
                              key={i}
                              onClick={
                                () => SubCategoryProduct(x)
                                // () => navigate(`/subCategories/${x.id}`)
                              }
                            >
                              {x.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                ))}
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {/* {categoryList && categoryList.length > 0
          ? categoryList.map((data, ind) => (
              <li
                onClick={() => handleSubCategory(data)}
                onMouseLeave={
                  handleLeaveLine
                  // () => setSubCategoryData([]),
                }
                key={ind}
              >
                <div className="cat-menu-list">
                  <img
                    src={`${Img_url}${data.picture}`}
                    alt=""
                    className="cat-logo"
                  />
                  <p className="hover_p_cat">{data.name}</p>
                  <ExpandMoreIcon
                    className={` ${
                      enter === data.id && "icon_r_180"
                    } cat-expand-icon`}
                  />
                </div>
                <div
                  onMouseLeave={() => setEnter("")}
                  className={` ${
                    enter === data.id ? "" : "d-none"
                  }  cat-menu-drop`}
                >
                  {enter && (
                    <ul>
                      <li
                        style={{ color: `var(--primary)` }}
                        onClick={() => navigate(`/subCategory/${data.id}`)}
                        className="d-flex align-items-center pointer"
                      >
                        All {data.name}{" "}
                        <NavigateNextIcon
                          sx={{ fontSize: "15px", marginTop: "2px" }}
                        />
                      </li>

                      {loading === true ? (
                        <Loader />
                      ) : subcategoryData && subcategoryData.length > 0 ? (
                        subcategoryData.map((x, i) => (
                          <li
                            className="pointer"
                            key={i}
                            onClick={() => navigate(`/subCategories/${x.id}`)}
                          >
                            {x.name}
                          </li>
                        ))
                      ) : (
                        ""
                      )}
                    </ul>
                  )}
                </div>
              </li>
            ))
          : ""} */}
      </ul>
    </>
  );
};

export default CategoriesMenu;

// relate prod me is_featured =1 payload bhejn hai
